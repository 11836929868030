import {
    SURVEY_YEAR_NAME_LIST,
    SURVEY_UUID_2023,
    SURVEY_UUID_2023_PRELIM,
    SURVEY_YEAR_2023,
    SURVEY_YEAR_2023_PRELIM,
    SURVEY_UUID_2023_2024_PRELIM,
    SURVEY_YEAR_2023_2024_PRELIM,
    SURVEY_UUID_2023_2024,
    SURVEY_YEAR_2023_2024,
    SURVEY_UUID_2024_PRELIM,
    SURVEY_YEAR_2024_PRELIM,
    SURVEY_UUID_2024,
    SURVEY_YEAR_2024,
    SURVEY_UUID_2024_2025_PRELIM,
    SURVEY_YEAR_2024_2025_PRELIM,
    SURVEY_UUID_2024_2025,
    SURVEY_YEAR_2024_2025,
} from '../constants/constants'
import { REACT_APP_PAGE_ADDRESS } from '../config'

function SurveyHome() {
    const renderSurveyButton = (uuid, yearName, isPreliminary = false) => {
        const surveyUrl = `${REACT_APP_PAGE_ADDRESS}${uuid}`
        const surveyTitle = `${yearName} ${
            isPreliminary ? ' Prelim ' : ' Final '
        }`

        return (
            <div>
                <br />
                <a
                    className="btn btn-primary btn-lg"
                    href={surveyUrl}
                    role="button"
                >
                    {surveyTitle}
                </a>
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
                {renderSurveyButton(
                    SURVEY_UUID_2023_PRELIM,
                    SURVEY_YEAR_NAME_LIST[SURVEY_YEAR_2023_PRELIM],
                    true
                )}
                {renderSurveyButton(
                    SURVEY_UUID_2023,
                    SURVEY_YEAR_NAME_LIST[SURVEY_YEAR_2023]
                )}
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
                {renderSurveyButton(
                    SURVEY_UUID_2023_2024_PRELIM,
                    SURVEY_YEAR_NAME_LIST[SURVEY_YEAR_2023_2024_PRELIM],
                    true
                )}
                {renderSurveyButton(
                    SURVEY_UUID_2023_2024,
                    SURVEY_YEAR_NAME_LIST[SURVEY_YEAR_2023_2024]
                )}
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
                {renderSurveyButton(
                    SURVEY_UUID_2024_PRELIM,
                    SURVEY_YEAR_NAME_LIST[SURVEY_YEAR_2024_PRELIM],
                    true
                )}
                {renderSurveyButton(
                    SURVEY_UUID_2024,
                    SURVEY_YEAR_NAME_LIST[SURVEY_YEAR_2024]
                )}
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
                {renderSurveyButton(
                    SURVEY_UUID_2024_2025_PRELIM,
                    SURVEY_YEAR_NAME_LIST[SURVEY_YEAR_2024_2025_PRELIM],
                    true
                )}
                {renderSurveyButton(
                    SURVEY_UUID_2024_2025,
                    SURVEY_YEAR_NAME_LIST[SURVEY_YEAR_2024_2025]
                )}
            </div>
        </div>
    )
}

export default SurveyHome
