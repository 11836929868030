import {
    SURVEY_YEAR_2022_2023,
    SURVEY_YEAR_2023,
    SURVEY_YEAR_2023_2024,
    SURVEY_YEAR_2024,
    SURVEY_YEAR_2024_2025,
} from './constants'

const PRACTICE_NORTH_AMERICA_COUNTRY_LIST = {
    [SURVEY_YEAR_2022_2023]: [
        { value: 'United States', label: 'United States' },
        { value: 'Canada', label: 'Canada' },
        // { value: 'Mexico', label: 'Mexico' },
    ],
    [SURVEY_YEAR_2023]: [
        { value: 'United States', label: 'United States' },
        { value: 'Canada', label: 'Canada' },
        // { value: 'Mexico', label: 'Mexico' },
    ],
    [SURVEY_YEAR_2024_2025]: [
        { value: 'United States', label: 'United States' },
        { value: 'Canada', label: 'Canada' },
        // { value: 'Mexico', label: 'Mexico' },
    ],
}

const PRACTICE_LATIN_AMERICA_COUNTRY_LIST = {
    [SURVEY_YEAR_2022_2023]: [
        // { value: 'Argentina', label: 'Argentina' },
        // // { value: 'Bahamas', label: 'Bahamas' },
        // // { value: 'Barbados', label: 'Barbados' },
        // // { value: 'Belize', label: 'Belize' },
        // // { value: 'Bermuda', label: 'Bermuda' },
        // // { value: 'Bolivia', label: 'Bolivia' },
        // { value: 'Brazil', label: 'Brazil' },
        // // { value: 'Cayman Islands', label: 'Cayman Islands' },
        // { value: 'Chile', label: 'Chile' },
        // { value: 'Colombia', label: 'Colombia' },
        // { value: 'Costa Rica', label: 'Costa Rica' },
        // // { value: 'Cuba', label: 'Cuba' },
        // // { value: 'Curaçao', label: 'Curaçao' },
        // { value: 'Dominican Republic', label: 'Dominican Republic' },
        // { value: 'Ecuador', label: 'Ecuador' },
        // // { value: 'El Salvador', label: 'El Salvador' },
        // { value: 'Guatemala', label: 'Guatemala' },
        // // { value: 'Guyana', label: 'Guyana' },
        // // { value: 'Haiti', label: 'Haiti' },
        // // { value: 'Honduras', label: 'Honduras' },
        // // { value: 'Jamaica', label: 'Jamaica' },
        // // { value: 'Nicaragua', label: 'Nicaragua' },
        // { value: 'Panama', label: 'Panama' },
        // // { value: 'Paraguay', label: 'Paraguay' },
        // { value: 'Peru', label: 'Peru' },
        // { value: 'Puerto Rico', label: 'Puerto Rico' },
        // // { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
        // // { value: 'Uruguay', label: 'Uruguay' },
        // // { value: 'Venezuela', label: 'Venezuela' },
    ],
    [SURVEY_YEAR_2023]: [
        // { value: 'Argentina', label: 'Argentina' },
        // { value: 'Bahamas', label: 'Bahamas' },
        // { value: 'Barbados', label: 'Barbados' },
        // { value: 'Belize', label: 'Belize' },
        // { value: 'Bermuda', label: 'Bermuda' },
        // { value: 'Bolivia', label: 'Bolivia' },
        // { value: 'Brazil', label: 'Brazil' },
        // { value: 'Cayman Islands', label: 'Cayman Islands' },
        // { value: 'Chile', label: 'Chile' },
        // { value: 'Colombia', label: 'Colombia' },
        // { value: 'Costa Rica', label: 'Costa Rica' },
        // { value: 'Cuba', label: 'Cuba' },
        // { value: 'Curaçao', label: 'Curaçao' },
        // { value: 'Dominican Republic', label: 'Dominican Republic' },
        // { value: 'Ecuador', label: 'Ecuador' },
        // { value: 'El Salvador', label: 'El Salvador' },
        // { value: 'Guatemala', label: 'Guatemala' },
        // { value: 'Guyana', label: 'Guyana' },
        // { value: 'Haiti', label: 'Haiti' },
        // { value: 'Honduras', label: 'Honduras' },
        // { value: 'Jamaica', label: 'Jamaica' },
        // { value: 'Nicaragua', label: 'Nicaragua' },
        // { value: 'Panama', label: 'Panama' },
        // { value: 'Paraguay', label: 'Paraguay' },
        // { value: 'Peru', label: 'Peru' },
        // { value: 'Puerto Rico', label: 'Puerto Rico' },
        // { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
        // { value: 'Uruguay', label: 'Uruguay' },
        // { value: 'Venezuela', label: 'Venezuela' },
    ],
    [SURVEY_YEAR_2024_2025]: [
        // { value: 'Argentina', label: 'Argentina' },
        // { value: 'Bahamas', label: 'Bahamas' },
        // { value: 'Barbados', label: 'Barbados' },
        // { value: 'Belize', label: 'Belize' },
        // { value: 'Bermuda', label: 'Bermuda' },
        // { value: 'Bolivia', label: 'Bolivia' },
        // { value: 'Brazil', label: 'Brazil' },
        // { value: 'Cayman Islands', label: 'Cayman Islands' },
        // { value: 'Chile', label: 'Chile' },
        // { value: 'Colombia', label: 'Colombia' },
        // { value: 'Costa Rica', label: 'Costa Rica' },
        // { value: 'Cuba', label: 'Cuba' },
        // { value: 'Curaçao', label: 'Curaçao' },
        // { value: 'Dominican Republic', label: 'Dominican Republic' },
        // { value: 'Ecuador', label: 'Ecuador' },
        // { value: 'El Salvador', label: 'El Salvador' },
        // { value: 'Guatemala', label: 'Guatemala' },
        // { value: 'Guyana', label: 'Guyana' },
        // { value: 'Haiti', label: 'Haiti' },
        // { value: 'Honduras', label: 'Honduras' },
        // { value: 'Jamaica', label: 'Jamaica' },
        // { value: 'Nicaragua', label: 'Nicaragua' },
        // { value: 'Panama', label: 'Panama' },
        // { value: 'Paraguay', label: 'Paraguay' },
        // { value: 'Peru', label: 'Peru' },
        // { value: 'Puerto Rico', label: 'Puerto Rico' },
        // { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
        // { value: 'Uruguay', label: 'Uruguay' },
        // { value: 'Venezuela', label: 'Venezuela' },
    ],
}

const PRACTICE_AFRICA_COUNTRY_LIST = {
    [SURVEY_YEAR_2022_2023]: [
        // // { value: 'Algeria', label: 'Algeria' },
        // // { value: 'Angola', label: 'Angola' },
        // // { value: 'Benin', label: 'Benin' },
        // // { value: 'Botswana', label: 'Botswana' },
        // // { value: 'Burkina Faso', label: 'Burkina Faso' },
        // // { value: 'Burundi', label: 'Burundi' },
        // // { value: 'Cameroon', label: 'Cameroon' },
        // // { value: 'Chad', label: 'Chad' },
        // // {
        // //     value: 'Congo, Democratic Republic',
        // //     label: 'Congo, Democratic Republic',
        // // },
        // // { value: 'Congo, Republic of', label: 'Congo, Republic of' },
        // // {
        // //     value: "Côte d'Ivoire (Ivory Coast)",
        // //     label: "Côte d'Ivoire (Ivory Coast)",
        // // },
        // { value: 'Egypt', label: 'Egypt' },
        // // { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
        // // { value: 'Eritrea', label: 'Eritrea' },
        // // { value: 'Eswatini (Swaziland)', label: 'Eswatini (Swaziland)' },
        // // { value: 'Ethiopia', label: 'Ethiopia' },
        // // { value: 'Gabon', label: 'Gabon' },
        // // { value: 'Gambia', label: 'Gambia' },
        // // { value: 'Ghana', label: 'Ghana' },
        // // { value: 'Guinea, Republic of', label: 'Guinea, Republic of' },
        // // { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
        // { value: 'Kenya', label: 'Kenya' },
        // // { value: 'Libya', label: 'Libya' },
        // // { value: 'Madagascar', label: 'Madagascar' },
        // // { value: 'Malawi', label: 'Malawi' },
        // // { value: 'Mali', label: 'Mali' },
        // // { value: 'Mauritania', label: 'Mauritania' },
        // // { value: 'Mauritius', label: 'Mauritius' },
        // { value: 'Morocco', label: 'Morocco' },
        // // { value: 'Mozambique', label: 'Mozambique' },
        // // { value: 'Namibia', label: 'Namibia' },
        // // { value: 'Niger', label: 'Niger' },
        // { value: 'Nigeria', label: 'Nigeria' },
        // // { value: 'Rwanda', label: 'Rwanda' },
        // // { value: 'Senegal', label: 'Senegal' },
        // // { value: 'Sierra Leone', label: 'Sierra Leone' },
        // // { value: 'Somalia', label: 'Somalia' },
        // { value: 'South Africa', label: 'South Africa' },
        // // { value: 'Sudan (North Sudan)', label: 'Sudan (North Sudan)' },
        // // { value: 'Sudan, South', label: 'Sudan, South' },
        // // { value: 'Tanzania', label: 'Tanzania' },
        // // { value: 'Togo', label: 'Togo' },
        // // { value: 'Tunisia', label: 'Tunisia' },
        // // { value: 'Uganda', label: 'Uganda' },
        // // { value: 'Zambia', label: 'Zambia' },
        // // { value: 'Zimbabwe', label: 'Zimbabwe' },
    ],
    [SURVEY_YEAR_2023]: [
        // { value: 'Algeria', label: 'Algeria' },
        // { value: 'Angola', label: 'Angola' },
        // { value: 'Benin', label: 'Benin' },
        // { value: 'Botswana', label: 'Botswana' },
        // { value: 'Burkina Faso', label: 'Burkina Faso' },
        // { value: 'Burundi', label: 'Burundi' },
        // { value: 'Cameroon', label: 'Cameroon' },
        // { value: 'Chad', label: 'Chad' },
        // {
        //     value: 'Congo, Democratic Republic',
        //     label: 'Congo, Democratic Republic',
        // },
        // { value: 'Congo, Republic of', label: 'Congo, Republic of' },
        // {
        //     value: "Côte d'Ivoire (Ivory Coast)",
        //     label: "Côte d'Ivoire (Ivory Coast)",
        // },
        // { value: 'Egypt', label: 'Egypt' },
        // { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
        // { value: 'Eritrea', label: 'Eritrea' },
        // { value: 'Eswatini (Swaziland)', label: 'Eswatini (Swaziland)' },
        // { value: 'Ethiopia', label: 'Ethiopia' },
        // { value: 'Gabon', label: 'Gabon' },
        // { value: 'Gambia', label: 'Gambia' },
        // { value: 'Ghana', label: 'Ghana' },
        // { value: 'Guinea, Republic of', label: 'Guinea, Republic of' },
        // { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
        // { value: 'Kenya', label: 'Kenya' },
        // { value: 'Libya', label: 'Libya' },
        // { value: 'Madagascar', label: 'Madagascar' },
        // { value: 'Malawi', label: 'Malawi' },
        // { value: 'Mali', label: 'Mali' },
        // { value: 'Mauritania', label: 'Mauritania' },
        // { value: 'Mauritius', label: 'Mauritius' },
        // { value: 'Morocco', label: 'Morocco' },
        // { value: 'Mozambique', label: 'Mozambique' },
        // { value: 'Namibia', label: 'Namibia' },
        // { value: 'Niger', label: 'Niger' },
        // { value: 'Nigeria', label: 'Nigeria' },
        // { value: 'Rwanda', label: 'Rwanda' },
        // { value: 'Senegal', label: 'Senegal' },
        // { value: 'Sierra Leone', label: 'Sierra Leone' },
        // { value: 'Somalia', label: 'Somalia' },
        // { value: 'South Africa', label: 'South Africa' },
        // { value: 'Sudan (North Sudan)', label: 'Sudan (North Sudan)' },
        // { value: 'Sudan, South', label: 'Sudan, South' },
        // { value: 'Tanzania', label: 'Tanzania' },
        // { value: 'Togo', label: 'Togo' },
        // { value: 'Tunisia', label: 'Tunisia' },
        // { value: 'Uganda', label: 'Uganda' },
        // { value: 'Zambia', label: 'Zambia' },
        // { value: 'Zimbabwe', label: 'Zimbabwe' },
    ],
    [SURVEY_YEAR_2024_2025]: [
        // { value: 'Algeria', label: 'Algeria' },
        // { value: 'Angola', label: 'Angola' },
        // { value: 'Benin', label: 'Benin' },
        // { value: 'Botswana', label: 'Botswana' },
        // { value: 'Burkina Faso', label: 'Burkina Faso' },
        // { value: 'Burundi', label: 'Burundi' },
        // { value: 'Cameroon', label: 'Cameroon' },
        // { value: 'Chad', label: 'Chad' },
        // {
        //     value: 'Congo, Democratic Republic',
        //     label: 'Congo, Democratic Republic',
        // },
        // { value: 'Congo, Republic of', label: 'Congo, Republic of' },
        // {
        //     value: "Côte d'Ivoire (Ivory Coast)",
        //     label: "Côte d'Ivoire (Ivory Coast)",
        // },
        // { value: 'Egypt', label: 'Egypt' },
        // { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
        // { value: 'Eritrea', label: 'Eritrea' },
        // { value: 'Eswatini (Swaziland)', label: 'Eswatini (Swaziland)' },
        // { value: 'Ethiopia', label: 'Ethiopia' },
        // { value: 'Gabon', label: 'Gabon' },
        // { value: 'Gambia', label: 'Gambia' },
        // { value: 'Ghana', label: 'Ghana' },
        // { value: 'Guinea, Republic of', label: 'Guinea, Republic of' },
        // { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
        // { value: 'Kenya', label: 'Kenya' },
        // { value: 'Libya', label: 'Libya' },
        // { value: 'Madagascar', label: 'Madagascar' },
        // { value: 'Malawi', label: 'Malawi' },
        // { value: 'Mali', label: 'Mali' },
        // { value: 'Mauritania', label: 'Mauritania' },
        // { value: 'Mauritius', label: 'Mauritius' },
        // { value: 'Morocco', label: 'Morocco' },
        // { value: 'Mozambique', label: 'Mozambique' },
        // { value: 'Namibia', label: 'Namibia' },
        // { value: 'Niger', label: 'Niger' },
        // { value: 'Nigeria', label: 'Nigeria' },
        // { value: 'Rwanda', label: 'Rwanda' },
        // { value: 'Senegal', label: 'Senegal' },
        // { value: 'Sierra Leone', label: 'Sierra Leone' },
        // { value: 'Somalia', label: 'Somalia' },
        // { value: 'South Africa', label: 'South Africa' },
        // { value: 'Sudan (North Sudan)', label: 'Sudan (North Sudan)' },
        // { value: 'Sudan, South', label: 'Sudan, South' },
        // { value: 'Tanzania', label: 'Tanzania' },
        // { value: 'Togo', label: 'Togo' },
        // { value: 'Tunisia', label: 'Tunisia' },
        // { value: 'Uganda', label: 'Uganda' },
        // { value: 'Zambia', label: 'Zambia' },
        // { value: 'Zimbabwe', label: 'Zimbabwe' },
    ],
}

const PRACTICE_ASIA_COUNTY_LIST = {
    [SURVEY_YEAR_2022_2023]: [
        // // { value: 'Afghanistan', label: 'Afghanistan' },
        // // { value: 'Armenia', label: 'Armenia' },
        // // { value: 'Azerbaijan', label: 'Azerbaijan' },
        // // { value: 'Bahrain', label: 'Bahrain' },
        // { value: 'Bangladesh', label: 'Bangladesh' },
        // // { value: 'Brunei', label: 'Brunei' },
        // // { value: 'Cambodia', label: 'Cambodia' },
        // { value: 'China', label: 'China' },
        // // { value: 'Georgia', label: 'Georgia' },
        // { value: 'Hong Kong', label: 'Hong Kong' },
        // { value: 'India', label: 'India' },
        // { value: 'Indonesia', label: 'Indonesia' },
        // // { value: 'Iraq', label: 'Iraq' },
        // { value: 'Israel', label: 'Israel' },
        // { value: 'Japan', label: 'Japan' },
        // // { value: 'Jordan', label: 'Jordan' },
        // { value: 'Kazakhstan', label: 'Kazakhstan' },
        // // { value: 'Kuwait', label: 'Kuwait' },
        // // { value: 'Laos', label: 'Laos' },
        // { value: 'Lebanon', label: 'Lebanon' },
        // // { value: 'Macau (Macao)', label: 'Macau (Macao)' },
        // { value: 'Malaysia', label: 'Malaysia' },
        // // { value: 'Mongolia', label: 'Mongolia' },
        // // { value: 'Myanmar (Burma)', label: 'Myanmar (Burma)' },
        // // { value: 'Oman', label: 'Oman' },
        // { value: 'Pakistan', label: 'Pakistan' },
        // { value: 'Philippines', label: 'Philippines' },
        // { value: 'Singapore', label: 'Singapore' },
        // { value: 'South Korea', label: 'South Korea' },
        // { value: 'Sri Lanka', label: 'Sri Lanka' },
        // { value: 'Taiwan', label: 'Taiwan' },
        // { value: 'Thailand', label: 'Thailand' },
        // // {
        // //     value: 'Timor-Leste (East Timor)',
        // //     label: 'Timor-Leste (East Timor)',
        // // },
        // { value: 'Turkey', label: 'Turkey' },
        // // { value: 'Turkmenistan', label: 'Turkmenistan' },
        // { value: 'Qatar', label: 'Qatar' },
        // { value: 'Saudi Arabia', label: 'Saudi Arabia' },
        // { value: 'United Arab Emirates', label: 'United Arab Emirates (UAE)' },
        // // { value: 'Uzbekistan', label: 'Uzbekistan' },
        // { value: 'Vietnam', label: 'Vietnam' },
        // // { value: 'Yemen', label: 'Yemen' },
    ],
    [SURVEY_YEAR_2023]: [
        // // { value: 'Afghanistan', label: 'Afghanistan' },
        // // { value: 'Armenia', label: 'Armenia' },
        // // { value: 'Azerbaijan', label: 'Azerbaijan' },
        // // { value: 'Bahrain', label: 'Bahrain' },
        // // { value: 'Bangladesh', label: 'Bangladesh' },
        // // { value: 'Brunei', label: 'Brunei' },
        // // { value: 'Cambodia', label: 'Cambodia' },
        // { value: 'China', label: 'China' },
        // // { value: 'Georgia', label: 'Georgia' },
        // // { value: 'Hong Kong', label: 'Hong Kong' },
        // { value: 'India', label: 'India' },
        // { value: 'Indonesia', label: 'Indonesia' },
        // // { value: 'Iraq', label: 'Iraq' },
        // // { value: 'Israel', label: 'Israel' },
        // { value: 'Japan', label: 'Japan' },
        // // { value: 'Jordan', label: 'Jordan' },
        // // { value: 'Kazakhstan', label: 'Kazakhstan' },
        // // { value: 'Kuwait', label: 'Kuwait' },
        // // { value: 'Laos', label: 'Laos' },
        // // { value: 'Lebanon', label: 'Lebanon' },
        // // { value: 'Macau (Macao)', label: 'Macau (Macao)' },
        // { value: 'Malaysia', label: 'Malaysia' },
        // // { value: 'Mongolia', label: 'Mongolia' },
        // // { value: 'Myanmar (Burma)', label: 'Myanmar (Burma)' },
        // // { value: 'Oman', label: 'Oman' },
        // // { value: 'Pakistan', label: 'Pakistan' },
        // { value: 'Philippines', label: 'Philippines' },
        // { value: 'Singapore', label: 'Singapore' },
        // // { value: 'South Korea', label: 'South Korea' },
        // // { value: 'Sri Lanka', label: 'Sri Lanka' },
        // // { value: 'Taiwan', label: 'Taiwan' },
        // { value: 'Thailand', label: 'Thailand' },
        // // {
        // //     value: 'Timor-Leste (East Timor)',
        // //     label: 'Timor-Leste (East Timor)',
        // // },
        // // { value: 'Turkey', label: 'Turkey' },
        // // { value: 'Turkmenistan', label: 'Turkmenistan' },
        // // { value: 'Qatar', label: 'Qatar' },
        // // { value: 'Saudi Arabia', label: 'Saudi Arabia' },
        // { value: 'United Arab Emirates', label: 'United Arab Emirates (UAE)' },
        // // { value: 'Uzbekistan', label: 'Uzbekistan' },
        // { value: 'Vietnam', label: 'Vietnam' },
        // // { value: 'Yemen', label: 'Yemen' },
    ],
    [SURVEY_YEAR_2024_2025]: [
        // // { value: 'Afghanistan', label: 'Afghanistan' },
        // // { value: 'Armenia', label: 'Armenia' },
        // // { value: 'Azerbaijan', label: 'Azerbaijan' },
        // // { value: 'Bahrain', label: 'Bahrain' },
        // // { value: 'Bangladesh', label: 'Bangladesh' },
        // // { value: 'Brunei', label: 'Brunei' },
        // // { value: 'Cambodia', label: 'Cambodia' },
        // { value: 'China', label: 'China' },
        // // { value: 'Georgia', label: 'Georgia' },
        // // { value: 'Hong Kong', label: 'Hong Kong' },
        // { value: 'India', label: 'India' },
        // { value: 'Indonesia', label: 'Indonesia' },
        // // { value: 'Iraq', label: 'Iraq' },
        // // { value: 'Israel', label: 'Israel' },
        // { value: 'Japan', label: 'Japan' },
        // // { value: 'Jordan', label: 'Jordan' },
        // // { value: 'Kazakhstan', label: 'Kazakhstan' },
        // // { value: 'Kuwait', label: 'Kuwait' },
        // // { value: 'Laos', label: 'Laos' },
        // // { value: 'Lebanon', label: 'Lebanon' },
        // // { value: 'Macau (Macao)', label: 'Macau (Macao)' },
        // { value: 'Malaysia', label: 'Malaysia' },
        // // { value: 'Mongolia', label: 'Mongolia' },
        // // { value: 'Myanmar (Burma)', label: 'Myanmar (Burma)' },
        // // { value: 'Oman', label: 'Oman' },
        // // { value: 'Pakistan', label: 'Pakistan' },
        // { value: 'Philippines', label: 'Philippines' },
        // { value: 'Singapore', label: 'Singapore' },
        // // { value: 'South Korea', label: 'South Korea' },
        // // { value: 'Sri Lanka', label: 'Sri Lanka' },
        // // { value: 'Taiwan', label: 'Taiwan' },
        // { value: 'Thailand', label: 'Thailand' },
        // // {
        // //     value: 'Timor-Leste (East Timor)',
        // //     label: 'Timor-Leste (East Timor)',
        // // },
        // // { value: 'Turkey', label: 'Turkey' },
        // // { value: 'Turkmenistan', label: 'Turkmenistan' },
        // // { value: 'Qatar', label: 'Qatar' },
        // // { value: 'Saudi Arabia', label: 'Saudi Arabia' },
        // { value: 'United Arab Emirates', label: 'United Arab Emirates (UAE)' },
        // // { value: 'Uzbekistan', label: 'Uzbekistan' },
        // { value: 'Vietnam', label: 'Vietnam' },
        // // { value: 'Yemen', label: 'Yemen' },
    ],
}

const PRACTICE_EUROPE_COUNTRY_LIST = {
    [SURVEY_YEAR_2022_2023]: [
        // // { value: 'Albania', label: 'Albania' },
        // // { value: 'Andorra', label: 'Andorra' },
        // { value: 'Austria', label: 'Austria' },
        // // { value: 'Belarus', label: 'Belarus' },
        // { value: 'Belgium', label: 'Belgium' },
        // { value: 'Bulgaria', label: 'Bulgaria' },
        // // { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
        // { value: 'Croatia', label: 'Croatia' },
        // // { value: 'Cyprus', label: 'Cyprus' },
        // {
        //     value: 'Czechia (Czech Republic)',
        //     label: 'Czechia (Czech Republic)',
        // },
        // { value: 'Denmark', label: 'Denmark' },
        // { value: 'Estonia', label: 'Estonia' },
        // { value: 'Finland', label: 'Finland' },
        { value: 'France', label: 'France' },
        { value: 'Germany', label: 'Germany' },
        // // { value: 'Gibraltar', label: 'Gibraltar' },
        // { value: 'Greece', label: 'Greece' },
        // { value: 'Hungary', label: 'Hungary' },
        // // { value: 'Iceland', label: 'Iceland' },
        // { value: 'Ireland', label: 'Ireland' },
        // { value: 'Italy', label: 'Italy' },
        // // { value: 'Kosovo', label: 'Kosovo' },
        // { value: 'Latvia', label: 'Latvia' },
        // { value: 'Lithuania', label: 'Lithuania' },
        // { value: 'Luxembourg', label: 'Luxembourg' },
        // // { value: 'Macedonia, North', label: 'Macedonia, North' },
        // // { value: 'Malta', label: 'Malta' },
        // // { value: 'Moldova', label: 'Moldova' },
        // // { value: 'Monaco', label: 'Monaco' },
        // // { value: 'Montenegro', label: 'Montenegro' },
        // { value: 'Netherlands', label: 'Netherlands' },
        // { value: 'Norway', label: 'Norway' },
        // { value: 'Poland', label: 'Poland' },
        // { value: 'Portugal', label: 'Portugal' },
        // { value: 'Romania', label: 'Romania' },
        // { value: 'Russia', label: 'Russia' },
        // // { value: 'San Marino', label: 'San Marino' },
        // { value: 'Serbia', label: 'Serbia' },
        // { value: 'Slovakia', label: 'Slovakia' },
        // { value: 'Slovenia', label: 'Slovenia' },
        // { value: 'Spain', label: 'Spain' },
        // { value: 'Sweden', label: 'Sweden' },
        // { value: 'Switzerland', label: 'Switzerland' },
        // { value: 'Ukraine', label: 'Ukraine' },
        { value: 'United Kingdom', label: 'United Kingdom (UK)' },
    ],
    [SURVEY_YEAR_2023]: [
        // // { value: 'Albania', label: 'Albania' },
        // // { value: 'Andorra', label: 'Andorra' },
        // // { value: 'Austria', label: 'Austria' },
        // // { value: 'Belarus', label: 'Belarus' },
        // { value: 'Belgium', label: 'Belgium' },
        // // { value: 'Bulgaria', label: 'Bulgaria' },
        // // { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
        // // { value: 'Croatia', label: 'Croatia' },
        // // { value: 'Cyprus', label: 'Cyprus' },
        // // {
        // //     value: 'Czechia (Czech Republic)',
        // //     label: 'Czechia (Czech Republic)',
        // // },
        // { value: 'Denmark', label: 'Denmark' },
        // // { value: 'Estonia', label: 'Estonia' },
        // // { value: 'Finland', label: 'Finland' },
        // { value: 'France', label: 'France' },
        // { value: 'Germany', label: 'Germany' },
        // // { value: 'Gibraltar', label: 'Gibraltar' },
        // // { value: 'Greece', label: 'Greece' },
        // // { value: 'Hungary', label: 'Hungary' },
        // { value: 'Iceland', label: 'Iceland' },
        // { value: 'Ireland', label: 'Ireland' },
        // { value: 'Italy', label: 'Italy' },
        // // { value: 'Kosovo', label: 'Kosovo' },
        // // { value: 'Latvia', label: 'Latvia' },
        // // { value: 'Lithuania', label: 'Lithuania' },
        // // { value: 'Luxembourg', label: 'Luxembourg' },
        // // { value: 'Macedonia, North', label: 'Macedonia, North' },
        // // { value: 'Malta', label: 'Malta' },
        // // { value: 'Moldova', label: 'Moldova' },
        // // { value: 'Monaco', label: 'Monaco' },
        // // { value: 'Montenegro', label: 'Montenegro' },
        // { value: 'Netherlands', label: 'Netherlands' },
        // // { value: 'Norway', label: 'Norway' },
        // { value: 'Poland', label: 'Poland' },
        // // { value: 'Portugal', label: 'Portugal' },
        // // { value: 'Romania', label: 'Romania' },
        // // { value: 'Russia', label: 'Russia' },
        // // { value: 'San Marino', label: 'San Marino' },
        // // { value: 'Serbia', label: 'Serbia' },
        // // { value: 'Slovakia', label: 'Slovakia' },
        // // { value: 'Slovenia', label: 'Slovenia' },
        // { value: 'Spain', label: 'Spain' },
        // { value: 'Sweden', label: 'Sweden' },
        // { value: 'Switzerland', label: 'Switzerland' },
        // // { value: 'Ukraine', label: 'Ukraine' },
        { value: 'United Kingdom', label: 'United Kingdom (UK)' },
    ],
    [SURVEY_YEAR_2024_2025]: [
        // // { value: 'Albania', label: 'Albania' },
        // // { value: 'Andorra', label: 'Andorra' },
        // // { value: 'Austria', label: 'Austria' },
        // // { value: 'Belarus', label: 'Belarus' },
        // { value: 'Belgium', label: 'Belgium' },
        // // { value: 'Bulgaria', label: 'Bulgaria' },
        // // { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
        // // { value: 'Croatia', label: 'Croatia' },
        // // { value: 'Cyprus', label: 'Cyprus' },
        // // {
        // //     value: 'Czechia (Czech Republic)',
        // //     label: 'Czechia (Czech Republic)',
        // // },
        // { value: 'Denmark', label: 'Denmark' },
        // // { value: 'Estonia', label: 'Estonia' },
        // // { value: 'Finland', label: 'Finland' },
        // { value: 'France', label: 'France' },
        // { value: 'Germany', label: 'Germany' },
        // // { value: 'Gibraltar', label: 'Gibraltar' },
        // // { value: 'Greece', label: 'Greece' },
        // // { value: 'Hungary', label: 'Hungary' },
        // { value: 'Iceland', label: 'Iceland' },
        // { value: 'Ireland', label: 'Ireland' },
        // { value: 'Italy', label: 'Italy' },
        // // { value: 'Kosovo', label: 'Kosovo' },
        // // { value: 'Latvia', label: 'Latvia' },
        // // { value: 'Lithuania', label: 'Lithuania' },
        // // { value: 'Luxembourg', label: 'Luxembourg' },
        // // { value: 'Macedonia, North', label: 'Macedonia, North' },
        // // { value: 'Malta', label: 'Malta' },
        // // { value: 'Moldova', label: 'Moldova' },
        // // { value: 'Monaco', label: 'Monaco' },
        // // { value: 'Montenegro', label: 'Montenegro' },
        // { value: 'Netherlands', label: 'Netherlands' },
        // // { value: 'Norway', label: 'Norway' },
        // { value: 'Poland', label: 'Poland' },
        // // { value: 'Portugal', label: 'Portugal' },
        // // { value: 'Romania', label: 'Romania' },
        // // { value: 'Russia', label: 'Russia' },
        // // { value: 'San Marino', label: 'San Marino' },
        // // { value: 'Serbia', label: 'Serbia' },
        // // { value: 'Slovakia', label: 'Slovakia' },
        // // { value: 'Slovenia', label: 'Slovenia' },
        // { value: 'Spain', label: 'Spain' },
        // { value: 'Sweden', label: 'Sweden' },
        // { value: 'Switzerland', label: 'Switzerland' },
        // // { value: 'Ukraine', label: 'Ukraine' },
        { value: 'United Kingdom', label: 'United Kingdom (UK)' },
    ],
}

const PRACTICE_OCEANIA_COUNTRY_LIST = {
    [SURVEY_YEAR_2022_2023]: [
        // { value: 'Australia', label: 'Australia' },
        // // { value: 'Fiji', label: 'Fiji' },
        // // { value: 'Micronesia', label: 'Micronesia' },
        // { value: 'New Zealand', label: 'New Zealand' },
        // { value: 'Papua New Guinea', label: 'Papua New Guinea' }
    ],
    [SURVEY_YEAR_2023]: [
        // { value: 'Australia', label: 'Australia' },
        // { value: 'Fiji', label: 'Fiji' },
        // { value: 'Micronesia', label: 'Micronesia' },
        // { value: 'New Zealand', label: 'New Zealand' },
        // { value: 'Papua New Guinea', label: 'Papua New Guinea' }
    ],
    [SURVEY_YEAR_2024_2025]: [
        // { value: 'Australia', label: 'Australia' },
        // { value: 'Fiji', label: 'Fiji' },
        // { value: 'Micronesia', label: 'Micronesia' },
        // { value: 'New Zealand', label: 'New Zealand' },
        // { value: 'Papua New Guinea', label: 'Papua New Guinea' }
    ],
}

const PRACTICE_GLOBAL_COUNTRY_LIST = {
    [SURVEY_YEAR_2022_2023]: [
        { value: 'Global', label: 'Global: All Countries' },
    ],
    [SURVEY_YEAR_2023]: [{ value: 'Global', label: 'Global: All Countries' }],
    [SURVEY_YEAR_2024_2025]: [
        { value: 'Global', label: 'Global: All Countries' },
    ],
}

export const PRACTICE_GROUPED_COUNTRY_OPTIONS = {
    [SURVEY_YEAR_2022_2023]: [
        {
            label: 'Global',
            options: PRACTICE_GLOBAL_COUNTRY_LIST[SURVEY_YEAR_2022_2023],
        },
        {
            label: 'North America',
            options: PRACTICE_NORTH_AMERICA_COUNTRY_LIST[SURVEY_YEAR_2022_2023],
        },
        {
            label: 'Latin America',
            options: PRACTICE_LATIN_AMERICA_COUNTRY_LIST[SURVEY_YEAR_2022_2023],
        },
        {
            label: 'Africa',
            options: PRACTICE_AFRICA_COUNTRY_LIST[SURVEY_YEAR_2022_2023],
        },
        {
            label: 'Asia',
            options: PRACTICE_ASIA_COUNTY_LIST[SURVEY_YEAR_2022_2023],
        },
        {
            label: 'Europe',
            options: PRACTICE_EUROPE_COUNTRY_LIST[SURVEY_YEAR_2022_2023],
        },
        {
            label: 'Oceania',
            options: PRACTICE_OCEANIA_COUNTRY_LIST[SURVEY_YEAR_2022_2023],
        },
    ],

    [SURVEY_YEAR_2023]: [
        {
            label: 'Global',
            options: PRACTICE_GLOBAL_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'North America',
            options: PRACTICE_NORTH_AMERICA_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'Latin America',
            options: PRACTICE_LATIN_AMERICA_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'Africa',
            options: PRACTICE_AFRICA_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'Asia',
            options: PRACTICE_ASIA_COUNTY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'Europe',
            options: PRACTICE_EUROPE_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'Oceania',
            options: PRACTICE_OCEANIA_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
    ],
    [SURVEY_YEAR_2023_2024]: [
        {
            label: 'Global',
            options: PRACTICE_GLOBAL_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'North America',
            options: PRACTICE_NORTH_AMERICA_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'Latin America',
            options: PRACTICE_LATIN_AMERICA_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'Africa',
            options: PRACTICE_AFRICA_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'Asia',
            options: PRACTICE_ASIA_COUNTY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'Europe',
            options: PRACTICE_EUROPE_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'Oceania',
            options: PRACTICE_OCEANIA_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
    ],
    [SURVEY_YEAR_2024]: [
        {
            label: 'Global',
            options: PRACTICE_GLOBAL_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'North America',
            options: PRACTICE_NORTH_AMERICA_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'Latin America',
            options: PRACTICE_LATIN_AMERICA_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'Africa',
            options: PRACTICE_AFRICA_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'Asia',
            options: PRACTICE_ASIA_COUNTY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'Europe',
            options: PRACTICE_EUROPE_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
        {
            label: 'Oceania',
            options: PRACTICE_OCEANIA_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
    ],
    [SURVEY_YEAR_2024_2025]: [
        {
            label: 'Global',
            options: PRACTICE_GLOBAL_COUNTRY_LIST[SURVEY_YEAR_2024_2025],
        },
        {
            label: 'North America',
            options: PRACTICE_NORTH_AMERICA_COUNTRY_LIST[SURVEY_YEAR_2024_2025],
        },
        {
            label: 'Latin America',
            options: PRACTICE_LATIN_AMERICA_COUNTRY_LIST[SURVEY_YEAR_2024_2025],
        },
        {
            label: 'Africa',
            options: PRACTICE_AFRICA_COUNTRY_LIST[SURVEY_YEAR_2024_2025],
        },
        {
            label: 'Asia',
            options: PRACTICE_ASIA_COUNTY_LIST[SURVEY_YEAR_2024_2025],
        },
        {
            label: 'Europe',
            options: PRACTICE_EUROPE_COUNTRY_LIST[SURVEY_YEAR_2024_2025],
        },
        {
            label: 'Oceania',
            options: PRACTICE_OCEANIA_COUNTRY_LIST[SURVEY_YEAR_2023],
        },
    ],
}
