const SECTORS_TECH = [
    { label: 'Technology - All Data', value: '[Technology]' },
    {
        label: 'Hardware / Electronics / Semiconductors',
        value: '[Technology] Hardware / Electronics / Semiconductors',
    },
    {
        label: 'Internet / Communication Services',
        value: '[Technology] Internet / Communication Services',
    },
    {
        label: 'Services & Consulting',
        value: '[Technology] Services & Consulting',
    },
    {
        label: 'Software / SaaS',
        value: '[Technology] Software / SaaS',
    },
    // {
    //     label: 'Other Technology Services',
    //     value: '[Technology] Other',
    // },
]

const SECTORS_LIFE_SCIENCE = [
    { label: 'Life Science - All Data', value: '[Life Science]' },
    {
        label: 'Biotechnology / Pharmaceutical',
        value: '[Life Science] Biotechnology / Pharmaceutical',
    },
    {
        label: 'Medical Devices & Equipment',
        value: '[Life Science] Medical Devices & Equipment',
    },
    {
        label: 'Services / Lab / Clinical / Research',
        value: '[Life Science] Services / Lab / Clinical / Research',
    },
    // {
    //     label: 'Other Life Science Services',
    //     value: '[Life Science] Other',
    // },
]

const SECTORS_HEALTH_CARE = [
    { label: 'Healthcare - All Data', value: '[Healthcare]' },
    { label: 'Hospitals', value: '[Healthcare] Hospitals' },
    { label: 'Outpatient', value: '[Healthcare] Outpatient' },
    // {
    //     label: 'Other Healthcare Services',
    //     value: '[Healthcare] Other Services',
    // },
]

const SECTORS_ENGINEERING = [
    { label: 'Engineering - All Data', value: '[Engineering]' },
    {
        label: 'Architecture / Design',
        value: '[Engineering] Architecture / Design',
    },
    {
        label: 'Construction',
        value: '[Engineering] Construction',
    },
    // {
    //     label: 'Other Engineering Services',
    //     value: '[Engineering] Other Services',
    // },
]

const SECTORS_OTHER = [
    // { label: 'All Data', value: '[Other]' },
    {
        label: 'Aerospace & Defense',
        value: '[Other] Aerospace & Defense',
    },
    {
        label: 'Agribusiness / Agriculture / Forestry',
        value: '[Other] Agribusiness / Agriculture / Forestry',
    },
    {
        label: 'Business Professional Services & Consulting',
        value: '[Other] Business Professional Services & Consulting',
    },
    {
        label: 'Charitable Organizations & Foundations',
        value: '[Other] Charitable Organizations & Foundations',
    },
    { label: 'Chemicals', value: '[Other] Chemicals' },
    {
        label: 'Consumer Goods & Services',
        value: '[Other] Consumer Goods & Services',
    },
    {
        label: 'Distribution & Wholesale Trade',
        value: '[Other] Distribution & Wholesale Trade',
    },
    { label: 'Education', value: '[Other] Education' },
    {
        label: 'Energy & Utilities',
        value: '[Other] Energy & Utilities',
    },
    {
        label: 'Financial Services',
        value: '[Other] Financial Services',
    },
    {
        label: 'Food & Beverage Processing & Production',
        value: '[Other] Food & Beverage Processing & Production',
    },
    {
        label: 'Government & Public Administration',
        value: '[Other] Government & Public Administration',
    },
    {
        label: 'Hospitality (Leisure / Recreation / Tourism)',
        value: '[Other] Hospitality (Leisure / Recreation / Tourism)',
    },
    {
        label: 'Industrial Goods & Services',
        value: '[Other] Industrial Goods & Services',
    },
    { label: 'Insurance', value: '[Other] Insurance' },
    { label: 'Manufacturing', value: '[Other] Manufacturing' },
    {
        label: 'Media & Information',
        value: '[Other] Media & Information',
    },
    { label: 'Mining', value: '[Other] Mining' },
    { label: 'Real Estate', value: '[Other] Real Estate' },
    {
        label: 'Retail & E-Commerce',
        value: '[Other] Retail & E-Commerce',
    },
    {
        label: 'Transportation / Logistics / Supply Chain / Warehousing',
        value: '[Other] Transportation / Logistics / Supply Chain / Warehousing',
    },
]

export const SECTOR_GROUP_ARR = [
    '[Technology]',
    '[Life Science]',
    '[Healthcare]',
    '[Engineering]',
    '[Other]',
    '[Not-For-Profit]',
    '[For-Profit]',
]

export const SECTOR_GROUPS = [
    { label: 'Technology', options: SECTORS_TECH },
    { label: 'Life Science', options: SECTORS_LIFE_SCIENCE },
    { label: 'Healthcare', options: SECTORS_HEALTH_CARE },
    { label: 'Engineering', options: SECTORS_ENGINEERING },
    { label: 'Other', options: SECTORS_OTHER },
]

export const VERTICALS = [
    {
        label: 'AdTech (Advertising Technology)',
        value: 'AdTech (Advertising Technology)',
    },
    {
        label: 'Artificial Intelligence (AI)',
        value: 'Artificial Intelligence (AI)',
    },
    { label: 'Big Data / Analytics', value: 'Big Data / Analytics' },
    {
        label: 'Blockchain',
        value: 'Blockchain',
    },
    {
        label: 'Clean Tech / Green Tech',
        value: 'Clean Tech / Green Tech',
    },
    {
        label: 'Climate Tech',
        value: 'Climate Tech',
    },
    { label: 'Cloud Computing', value: 'Cloud Computing' },
    { label: 'Cybersecurity', value: 'Cybersecurity' },
    // { label: 'Cryptocurrency', value: 'Cryptocurrency' },
    {
        label: 'Digital Marketing & Advertising',
        value: 'Digital Marketing & Advertising',
    },
    {
        label: 'EdTech (Education Technology)',
        value: 'EdTech (Education Technology)',
    },
    {
        label: 'EnergyTech (Energy Technology)',
        value: 'EnergyTech (Energy Technology)',
    },
    {
        label: 'FinTech (Financial Technology)',
        value: 'FinTech (Financial Technology)',
    },
    {
        label: 'InsurTech (Insurance Technology)',
        value: 'InsurTech (Insurance Technology)',
    },
    {
        label: 'Government Contractor',
        value: 'Government Contractor',
    },
    {
        label: 'Healthcare Technology',
        value: 'Healthcare Technology',
    },
    {
        label: 'HR Tech (Human Resources Technology)',
        value: 'HR Tech (Human Resources Technology)',
    },

    {
        label: 'Internet of Things (IoT)',
        value: 'Internet of Things (IoT)',
    },
    {
        label: 'MarTech (Marketing Technology)',
        value: 'MarTech (Marketing Technology)',
    },
    {
        label: 'Media / Gaming Technology',
        value: 'Media / Gaming Technology',
    },
    {
        label: 'MedTech (Medical Technology)',
        value: 'MedTech (Medical Technology)',
    },
    {
        label: 'Mobile / Wireless Technology',
        value: 'Mobile / Wireless Technology',
    },
    {
        label: 'RetailTech / eCommerce',
        value: 'RetailTech / eCommerce',
    },

    { label: 'Robotics / Drones', value: 'Robotics / Drones' },
    {
        label: 'Virtual Reality / Augmented Reality (VR / AR)',
        value: 'Virtual Reality / Augmented Reality (VR / AR)',
    },
    // { label: 'Other', value: 'Other' },
]

export const YEARS = [
    { label: '2023 & 2024', value: 'AllYears' },
    {
        label: '2023',
        value: 'Actual',
        tableHeader: '2023 \n % of Companies',
    },
    {
        label: '2024',
        value: 'Projected',
        tableHeader: '2024 \n % of Companies',
    },
]

export const JOB_FUNCTIONS = [
    { label: 'All Job Functions', value: 'All' },
    {
        label: 'Executives',
        value: 'EX',
        tableHeader: 'Executives\n \n \n % of Companies',
    },
    {
        label: 'Director & Manager Levels',
        value: 'DirectorManager',
        tableHeader: 'Director & Manager\nLevels\n \n % of Companies',
    },
    {
        label: 'Individual Contributor Professional Levels',
        value: 'Professional',
        tableHeader:
            'Individual Contributor\nProfessional Levels\n \n % of Companies',
    },
    {
        label: 'Individual Contributor Support Levels',
        value: 'Support',
        tableHeader:
            'Individual Contributor\nSupport Levels\n \n % of Companies',
    },
]

export const HEADCOUNT_OPTIONS = [
    { label: 'Up to 100', value: '1 to 100' },
    { label: '101 to 500', value: '101 to 500' },
    { label: '501 to 2,500', value: '501 to 2,500' },
    { label: '2,501 to 10,000', value: '2,501 to 10,000' },
    { label: '10,001 to 25,000', value: '10,001 to 25,000' },
    { label: 'Over 25,000', value: '25,000+' },
]

export const HEADCOUNT_OPTIONS_2024_2025 = [
    { label: 'Up to 100', value: '1 to 100' },
    { label: '101 to 500', value: '101 to 500' },
    { label: '501 to 2,500', value: '501 to 2,500' },
    { label: '2,501 to 10,000', value: '2,501 to 10,000' },
    { label: '10,001 to 25,000', value: '10,001 to 25,000' },
    { label: '25,000 to 100,000', value: '25,000 to 100,000' },
    { label: 'Over 100,000', value: '100,000 +' },
]

export const STARTUP_OPTIONS = [{ label: 'Yes', value: 'Yes' }]

const FOR_PROFIT_OPTIONS = [
    {
        label: 'For-Profit (Publicly Traded + Privately Owned)',
        value: '[For-Profit]',
    },
    {
        label: 'Publicly Traded on a Stock Exchange',
        value: '[For-Profit] Publicly Traded on a Stock Exchange',
    },
    {
        label: 'Privately Owned For Profit',
        value: '[For-Profit] Privately Owned For Profit',
    },
]

const NOT_FOR_PROFIT_OPTIONS = [
    {
        label: 'Not-For-Profit (Non-Profit + Government)',
        value: '[Not-For-Profit]',
    },
    {
        label: 'Non-Profit',
        value: '[Not-For-Profit] Non-Profit / Not-For-Profit',
    },
    {
        label: 'Government',
        value: '[Not-For-Profit] Government',
    },
]

export const OWNERSHIP_GROUPS = [
    { label: 'For-Profit', options: FOR_PROFIT_OPTIONS },
    { label: 'Not-For-Profit', options: NOT_FOR_PROFIT_OPTIONS },
]

export const ALL_DATA_CUT_OPTIONS = SECTORS_TECH.concat(
    SECTORS_LIFE_SCIENCE,
    SECTORS_HEALTH_CARE,
    SECTORS_ENGINEERING,
    SECTORS_OTHER,
    VERTICALS,
    HEADCOUNT_OPTIONS,
    HEADCOUNT_OPTIONS_2024_2025,
    STARTUP_OPTIONS,
    FOR_PROFIT_OPTIONS,
    NOT_FOR_PROFIT_OPTIONS,
    YEARS,
    JOB_FUNCTIONS
)
