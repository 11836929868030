import React, { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { Col, Container, Row } from 'react-bootstrap'
import {
    CURRENT_YEAR_TEXT,
    UPCOMING_YEAR_TEXT,
    SALARY_FREEZE_BUTTON_TEXT,
    UNAVAIL_DATA_BUTTON_TEXT,
    TEXT_NO_AVAIL_DATA,
    TEXT_DATA_CUTS_SELECTION,
    TEXT_INDUSTRY_VERTICAL,
    TEXT_INDUSTRY_SECTOR,
    TEXT_OWNERSHIP,
    TEXT_HEADCOUNT,
    TEXT_STARTUP,
    BOOLEAN_TEXT_TRUE,
    UPCOMING_YEAR_LIST,
    CURRENT_YEAR_LIST,
    SURVEY_UUID_LIST,
    TEXT_REGION_REPORT_SELECTION,
    PRELIM_SURVEY_LIST,
    EXPORT_OPTION_AVALIBLE_SURVEY_LIST,
    TEXT_ONE_DATA_CUT_SELECTION,
    REPORT_COLUMN_SELECTION_BUTTON_TEXT,
    TEXT_YEAR_REPORT_SELECTION,
    TEXT_OTHER_OPTIONS,
    REGION_SELECTED_TEXT,
    TEXT_SELECT_PLACEHOLDER,
    TABLE_COLUMN_NAME_N,
    BOOLEAN_TEXT_FALSE,
    SURVEY_COPY_RIGHT,
    SURVEY_YEAR_2024_2025_PRELIM,
    SURVEY_YEAR_2024_2025,
    TEXT_ANNOTATION_FOR_N_REGION,
} from '../constants/constants.js'
import {
    SECTOR_GROUPS,
    VERTICALS,
    OWNERSHIP_GROUPS,
    HEADCOUNT_OPTIONS,
    HEADCOUNT_OPTIONS_2024_2025,
    STARTUP_OPTIONS,
} from '../constants/datacutConstants'
import { REGION_OPTIONS } from '../constants/regionConstants'
import { REPORT_BASE_SALARY_INCREASE } from '../constants/countryReportConstants'
import {
    cellStyle,
    CheckboxPercentiles,
    RadioButton,
    Checkbox,
    getTableHeaderStyle,
    useQueryState,
    ReactSelect,
    IsValidDataCutSelection,
    IsValidOtherOptions,
    tableFooter,
    getDataCutSelectionTextTitle,
    getDataCutSelectionTextName,
} from '../helpers/helpers'
import {
    COLUMNS_TO_SHOW_ALL,
    Catalogues,
} from '../constants/reportOuputConstants'
import { formatGroupLabel } from '../docs/style.js'
import {
    REACT_APP_SERVER_ADDRESS,
    REACT_APP_API_KEY,
    REACT_APP_EXCEL_EXPORT,
} from '../config'
import PageNotFound from './404Page'
import { useParams } from 'react-router-dom'
import Popup from 'reactjs-popup'
import Button from 'react-bootstrap/Button'

function Region() {
    return (
        <div>
            <UserInput />
        </div>
    )
}

const UserInput = () => {
    const params = useParams()
    const survey = SURVEY_UUID_LIST[params.survey]
    const [regionValue, setRegionValue] = useQueryState('region')
    const [sectorValue, setSectorValue] = useQueryState('sector')
    const [verticalValue, setVerticalValue] = useQueryState('vertical')
    const [ownershipValue, setOwnershipValue] = useQueryState('ownership')
    const [headcountValue, setHeadcountValue] = useQueryState('headcount')
    const [startupValue, setStartupValue] = useQueryState('startup')
    const [salaryFreeze, setSalaryFreeze] = useQueryState('freeze')
    const [yearValue, setYearValue] = useQueryState('year')
    const [showNonReportableDataValue, setShowNonReportableDataValue] =
        useQueryState('alldata')

    const [showSpinner, setShowSpinner] = useState(true)
    const [items, setItems] = useState([])
    const [responses, setResponses] = useState({})

    const COLUMNS_TO_SHOW_MD = COLUMNS_TO_SHOW_ALL.filter(
        (item) => item['enabled'] === BOOLEAN_TEXT_TRUE
    )
    const [isCheckAll, setIsCheckAll] = useState(false)
    const [isCheck, setIsCheck] = useState(
        COLUMNS_TO_SHOW_MD.map((li) => li.value)
    )

    const [columnsOnTable, setColumnsOnTable] = useState([])
    const [headcountOptions, setHeadcountOptions] = useState(HEADCOUNT_OPTIONS)
    useEffect(() => {
        if (
            [SURVEY_YEAR_2024_2025_PRELIM, SURVEY_YEAR_2024_2025].includes(
                survey
            )
        ) {
            setHeadcountOptions(HEADCOUNT_OPTIONS_2024_2025)
        } else {
            setHeadcountOptions(HEADCOUNT_OPTIONS)
        }
    }, [survey])

    // Initialize isDownloading state variable
    const [isDownloading, setIsDownloading] = useState(false)

    useEffect(() => {
        setShowSpinner(true)
        fetchDataAndUpdateTable()
    }, [
        sectorValue,
        verticalValue,
        startupValue,
        ownershipValue,
        headcountValue,
        regionValue,
        salaryFreeze,
        yearValue,
        showNonReportableDataValue,
        isCheck,
    ])

    const fetchDataAndUpdateTable = async () => {
        if (!regionValue) {
            return
        }
        const options = {
            method: 'GET',
            url: `${REACT_APP_SERVER_ADDRESS}region`,
            params: {
                year: yearValue,
                zeros: salaryFreeze,
                ownership: ownershipValue,
                region: regionValue,
                sector: sectorValue,
                vertical: verticalValue,
                headcount: headcountValue,
                startup: startupValue,
                alldata: showNonReportableDataValue,
                survey: survey,
                columns: isCheck.join(','),
            },
        }

        try {
            const response = await axios.request(options)
            const respBody = response.data
            setResponses(respBody)
            const newData = respBody.results
            setItems(newData)
            setShowSpinner(false)
            const columnsToShow = COLUMNS_TO_SHOW_ALL.filter(function (obj) {
                return Object.keys(newData[0]).includes(obj.value)
            })

            setColumnsOnTable(columnsToShow)
        } catch (e) {}
    }
    const handleRegionChange = (event) => {
        setRegionValue(event.value)
    }

    const handleSectorChange = (event) => {
        if (event === null) {
            setSectorValue(undefined)
        } else {
            setSectorValue(event.value)
        }
    }

    const handleVerticalChange = (event) => {
        if (event === null) {
            setVerticalValue(undefined)
        } else {
            setVerticalValue(event.value)
        }
    }

    const handleOwnershipChange = (event) => {
        if (event === null) {
            setOwnershipValue(undefined)
        } else {
            setOwnershipValue(event.value)
        }
    }

    const handleHeadcountChange = (event) => {
        if (event === null) {
            setHeadcountValue(undefined)
        } else {
            setHeadcountValue(event.value)
        }
    }

    const handleStartupChange = (event) => {
        if (event === null) {
            setStartupValue(undefined)
        } else {
            setStartupValue(event.value)
        }
    }

    const handleSalaryFreezeChange = (event) => {
        event.target.checked.toString() === 'true'
            ? setSalaryFreeze(BOOLEAN_TEXT_TRUE)
            : setSalaryFreeze(BOOLEAN_TEXT_FALSE)
    }

    const handleShowNonReportableData = (event) => {
        event.target.checked.toString() === 'true'
            ? setShowNonReportableDataValue(BOOLEAN_TEXT_TRUE)
            : setShowNonReportableDataValue(BOOLEAN_TEXT_FALSE)
    }

    const handleActualChange = () => {
        setYearValue(CURRENT_YEAR_TEXT)
    }

    const handleProjectedChange = () => {
        setYearValue(UPCOMING_YEAR_TEXT)
    }

    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll)
        if (isCheckAll) {
            setIsCheck(COLUMNS_TO_SHOW_MD.map((li) => li.value))
        } else {
            setIsCheck(COLUMNS_TO_SHOW_ALL.map((li) => li.value))
        }
    }
    const handleClick = (event) => {
        const { id, checked } = event.target
        setIsCheck([...isCheck, id])
        if (!checked) {
            setIsCheck(isCheck.filter((item) => item !== id))
        }
    }

    const catalog = Catalogues.map(({ id, name, disabled }) => {
        return (
            <div>
                <CheckboxPercentiles
                    className="checkbox-container"
                    key={id}
                    type="checkbox"
                    name={name}
                    id={id}
                    handleClick={handleClick}
                    isChecked={isCheck.includes(id)}
                    disabled={disabled}
                />
                <label className="percentile-labels">{name}</label>
            </div>
        )
    })
    const DisplayDataItems = items.map((item) => {
        return (
            <tr>
                {columnsOnTable.map((column) => {
                    return (
                        <th className={cellStyle(regionValue, item, column)}>
                            {item[column.value] === '*'
                                ? item[column.value]
                                : item[column.value]
                                      .toLocaleString()
                                      .concat(column.percent)}
                        </th>
                    )
                })}
            </tr>
        )
    })
    const DisplayData =
        showSpinner === true ? (
            <div id="loader"></div>
        ) : (
            <table className="table table-sm">
                <thead className="thead-style">
                    {columnsOnTable.map((column) => {
                        return (
                            <th className="table-first-row">
                                {getTableHeaderStyle(column.name)}
                            </th>
                        )
                    })}
                </thead>
                <tbody>{DisplayDataItems}</tbody>
                {tableFooter(
                    survey,
                    'yes',
                    'no',
                    false,
                    TEXT_ANNOTATION_FOR_N_REGION
                )}
            </table>
        )

    const findReportableData = items.filter((item) => {
        return item[TABLE_COLUMN_NAME_N] > 0
    })

    const handleExcelDownload = async () => {
        setIsDownloading(true) // Set isDownloading to true at the start
        // const apiUrl = 'https://dev.private.culpepper.com/API/exportsbs/export'
        const requestData = responses

        requestData['copyright'] = SURVEY_COPY_RIGHT[survey]
        axios
            .post(REACT_APP_EXCEL_EXPORT, requestData, {
                responseType: 'blob', // Set the response type to 'blob' to receive binary data
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${REACT_APP_API_KEY}`,
                    'Content-Type': 'application/json',
                },
                crossdomain: true,
            })

            .then((response) => {
                // Check the status code of the response
                if (response.status !== 200) {
                    // If the status code is not 200, show an alert to the user
                    alert(
                        "The current download is not working, please use the 'Contact Us' on the bottom of the page to report this issue."
                    )
                } else {
                    // Get the "returnData" field from the API response
                    const blob = new Blob([response.data])
                    const fileUrl = URL.createObjectURL(blob)

                    // Create an anchor element
                    const downloadLink = document.createElement('a')
                    downloadLink.href = fileUrl
                    downloadLink.target = '_blank'
                    downloadLink.download = response.headers[
                        'content-disposition'
                    ]
                        .split('=')[1]
                        .split(';')[0]
                    downloadLink.click()
                }
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setIsDownloading(false) // Set isDownloading to false at the end
            })
    }
    if (
        IsValidDataCutSelection(
            sectorValue,
            verticalValue,
            ownershipValue,
            headcountValue,
            startupValue,
            headcountOptions
        ) ||
        IsValidOtherOptions(
            salaryFreeze,
            showNonReportableDataValue,
            yearValue
        ) ||
        (regionValue &&
            REGION_OPTIONS.find((c) => c.value === regionValue) === undefined)
    ) {
        return (
            <div>
                <PageNotFound />
            </div>
        )
    } else {
        return (
            <Container fluid>
                <div>
                    <Row>
                        <Col
                            sm={12}
                            lg={3}
                            className="pane printHidden"
                            style={!regionValue ? { height: '100vh' } : {}}
                        >
                            <ReactSelect
                                label={TEXT_REGION_REPORT_SELECTION}
                                placeholder={TEXT_SELECT_PLACEHOLDER}
                                className="react-select form-control p-0"
                                options={REGION_OPTIONS}
                                onChange={handleRegionChange}
                                value={
                                    regionValue
                                        ? REGION_OPTIONS.find(
                                              (c) => c.value === regionValue
                                          )
                                        : 'Global'
                                }
                                closeMenuOnSelect={true}
                            />

                            <p className="report-seperator">
                                {TEXT_YEAR_REPORT_SELECTION}
                            </p>
                            <div className="freeze-and-year-filters">
                                <RadioButton
                                    label={
                                        ' ' +
                                        CURRENT_YEAR_LIST[survey] +
                                        ' Actual'
                                    }
                                    value={yearValue === CURRENT_YEAR_TEXT}
                                    onChange={handleActualChange}
                                    disabled={!regionValue}
                                />
                                <tr></tr>
                                <RadioButton
                                    label={
                                        ' ' +
                                        UPCOMING_YEAR_LIST[survey] +
                                        ' Estimated / Projected'
                                    }
                                    value={
                                        yearValue === UPCOMING_YEAR_TEXT ||
                                        !yearValue
                                    }
                                    onChange={handleProjectedChange}
                                    disabled={!regionValue}
                                />
                            </div>

                            <div
                                className="datacuts-filters"
                                style={
                                    regionValue
                                        ? { display: 'block' }
                                        : { display: 'none' }
                                }
                            >
                                <p className="report-seperator">
                                    {PRELIM_SURVEY_LIST.includes(survey)
                                        ? TEXT_ONE_DATA_CUT_SELECTION
                                        : TEXT_DATA_CUTS_SELECTION}
                                </p>
                                <ReactSelect
                                    className="react-select form-control p-0"
                                    annotation={TEXT_INDUSTRY_SECTOR}
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    options={SECTOR_GROUPS}
                                    formatGroupLabel={formatGroupLabel}
                                    onChange={handleSectorChange}
                                    isDisabled={
                                        PRELIM_SURVEY_LIST.includes(survey)
                                            ? verticalValue ||
                                              ownershipValue ||
                                              headcountValue ||
                                              startupValue
                                            : false
                                    }
                                    value={
                                        sectorValue
                                            ? SECTOR_GROUPS.reduce(
                                                  (acc, val) =>
                                                      acc.concat(val.options),
                                                  []
                                              ).find(
                                                  (c) => c.value === sectorValue
                                              )
                                            : '-'
                                    }
                                    isClearable={true}
                                    closeMenuOnSelect={true}
                                />

                                {/*<span>{TEXT_INDUSTRY_VERTICAL}</span>*/}
                                <ReactSelect
                                    className="react-select form-control p-0"
                                    annotation={TEXT_INDUSTRY_VERTICAL}
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    options={VERTICALS}
                                    formatGroupLabel={formatGroupLabel}
                                    onChange={handleVerticalChange}
                                    value={
                                        verticalValue
                                            ? VERTICALS.find(
                                                  (c) =>
                                                      c.value === verticalValue
                                              )
                                            : '-'
                                    }
                                    isClearable
                                    isDisabled={
                                        PRELIM_SURVEY_LIST.includes(survey)
                                            ? sectorValue ||
                                              ownershipValue ||
                                              headcountValue ||
                                              startupValue
                                            : false
                                    }
                                    closeMenuOnSelect={true}
                                />
                                <ReactSelect
                                    className="react-select form-control p-0"
                                    annotation={TEXT_OWNERSHIP}
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    options={OWNERSHIP_GROUPS}
                                    formatGroupLabel={formatGroupLabel}
                                    onChange={handleOwnershipChange}
                                    value={
                                        ownershipValue
                                            ? OWNERSHIP_GROUPS.reduce(
                                                  (acc, val) =>
                                                      acc.concat(val.options),
                                                  []
                                              ).find(
                                                  (c) =>
                                                      c.value === ownershipValue
                                              )
                                            : '-'
                                    }
                                    isClearable
                                    isDisabled={
                                        PRELIM_SURVEY_LIST.includes(survey)
                                            ? sectorValue ||
                                              verticalValue ||
                                              headcountValue ||
                                              startupValue
                                            : false
                                    }
                                    closeMenuOnSelect={true}
                                />
                                <ReactSelect
                                    className="react-select form-control p-0"
                                    annotation={TEXT_HEADCOUNT}
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    options={headcountOptions}
                                    formatGroupLabel={formatGroupLabel}
                                    onChange={handleHeadcountChange}
                                    value={
                                        headcountValue
                                            ? headcountOptions.find(
                                                  (c) =>
                                                      c.value === headcountValue
                                              )
                                            : '-'
                                    }
                                    isClearable
                                    isDisabled={
                                        PRELIM_SURVEY_LIST.includes(survey)
                                            ? sectorValue ||
                                              verticalValue ||
                                              ownershipValue ||
                                              startupValue
                                            : false
                                    }
                                    closeMenuOnSelect={true}
                                />
                                <ReactSelect
                                    className="react-select form-control p-0"
                                    annotation={TEXT_STARTUP}
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    options={STARTUP_OPTIONS}
                                    formatGroupLabel={formatGroupLabel}
                                    onChange={handleStartupChange}
                                    value={
                                        startupValue
                                            ? STARTUP_OPTIONS.find(
                                                  (c) =>
                                                      c.value === startupValue
                                              )
                                            : '-'
                                    }
                                    isClearable
                                    isDisabled={
                                        PRELIM_SURVEY_LIST.includes(survey)
                                            ? sectorValue ||
                                              verticalValue ||
                                              ownershipValue ||
                                              headcountValue
                                            : false
                                    }
                                    closeMenuOnSelect={true}
                                />
                            </div>
                            <p
                                className="report-seperator"
                                style={
                                    regionValue
                                        ? { display: 'block' }
                                        : { display: 'none' }
                                }
                            >
                                {TEXT_OTHER_OPTIONS}
                            </p>
                            <div
                                className="freeze-and-year-filters"
                                style={
                                    regionValue
                                        ? { display: 'block' }
                                        : { display: 'none' }
                                }
                            >
                                <Checkbox
                                    label={SALARY_FREEZE_BUTTON_TEXT}
                                    value={
                                        !salaryFreeze ||
                                        salaryFreeze === BOOLEAN_TEXT_TRUE
                                    }
                                    onChange={handleSalaryFreezeChange}
                                    disabled={!regionValue}
                                />
                                <tr></tr>
                                <Checkbox
                                    label={UNAVAIL_DATA_BUTTON_TEXT}
                                    value={
                                        showNonReportableDataValue ===
                                            BOOLEAN_TEXT_TRUE ||
                                        !showNonReportableDataValue
                                    }
                                    onChange={handleShowNonReportableData}
                                    disabled={!regionValue}
                                />
                                <tr></tr>
                            </div>
                        </Col>
                        <Col
                            sm={12}
                            lg={9}
                            className="table-area"
                            style={
                                regionValue
                                    ? { display: 'block' }
                                    : { display: 'none' }
                            }
                        >
                            <div className="printVisible">
                                <div className="logo-print-view">
                                    <img
                                        src="https://ww2.culpepper.com/images/logos/CulpepperLogoNoTagline.svg"
                                        alt=""
                                        width="238.19"
                                    ></img>
                                </div>
                            </div>
                            <p className="table-title">
                                {yearValue === UPCOMING_YEAR_TEXT || !yearValue
                                    ? UPCOMING_YEAR_LIST[survey] +
                                      ' ' +
                                      UPCOMING_YEAR_TEXT
                                    : CURRENT_YEAR_LIST[survey] +
                                      ' ' +
                                      CURRENT_YEAR_TEXT}{' '}
                                {REPORT_BASE_SALARY_INCREASE}
                            </p>
                            <hr style={{ width: '100%' }} />{' '}
                            {EXPORT_OPTION_AVALIBLE_SURVEY_LIST.includes(
                                survey
                            ) ? (
                                <div>
                                    <img
                                        disabled={isDownloading}
                                        className="export-file-images"
                                        alt="Excel"
                                        src="https://ww3.culpepper.com/l/137751/2016-02-02/23qp6/137751/2041/excel.gif"
                                        onClick={(e) => {
                                            if (isDownloading) {
                                                e.preventDefault()
                                                return
                                            }
                                            handleExcelDownload()
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            filter: isDownloading
                                                ? 'grayscale(100%)'
                                                : 'none',
                                        }} // Show pointer cursor to indicate clickability and apply grayscale filter if image is disabled
                                    ></img>
                                </div>
                            ) : null}
                            <div className="container container-margin">
                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-xl-2 text-left">
                                        <span className="datacut-type">
                                            {REGION_SELECTED_TEXT}:
                                        </span>
                                    </div>
                                    <div className="col-auto text-left">
                                        {/*</div>*/}
                                        {/*<div className="col-6">*/}
                                        {regionValue}
                                    </div>
                                </div>

                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-xl-2 text-left">
                                        {getDataCutSelectionTextTitle(
                                            sectorValue,
                                            TEXT_INDUSTRY_SECTOR
                                        )}
                                    </div>
                                    <div className="col-auto text-left">
                                        {getDataCutSelectionTextName(
                                            sectorValue
                                        )}
                                    </div>
                                </div>
                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-xl-2 text-left">
                                        {getDataCutSelectionTextTitle(
                                            verticalValue,
                                            TEXT_INDUSTRY_VERTICAL
                                        )}
                                    </div>
                                    <div className="col-auto text-left">
                                        {getDataCutSelectionTextName(
                                            verticalValue
                                        )}
                                    </div>
                                </div>

                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-xl-2 text-left">
                                        {getDataCutSelectionTextTitle(
                                            ownershipValue,
                                            TEXT_OWNERSHIP
                                        )}
                                    </div>
                                    <div className="col-auto text-left">
                                        {getDataCutSelectionTextName(
                                            ownershipValue
                                        )}
                                    </div>
                                </div>

                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-xl-2 text-left">
                                        {getDataCutSelectionTextTitle(
                                            headcountValue,
                                            TEXT_HEADCOUNT
                                        )}
                                    </div>
                                    <div className="col-auto text-left">
                                        {getDataCutSelectionTextName(
                                            headcountValue
                                        )}
                                    </div>
                                </div>

                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-xl-2 text-left">
                                        {getDataCutSelectionTextTitle(
                                            startupValue,
                                            TEXT_STARTUP
                                        )}
                                    </div>
                                    <div className="col-auto text-left">
                                        {getDataCutSelectionTextName(
                                            startupValue
                                        )}
                                    </div>
                                </div>
                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-xl-2 text-left">
                                        <span className="datacut-type">
                                            {SALARY_FREEZE_BUTTON_TEXT}:
                                        </span>
                                    </div>
                                    <div className="col-auto text-left">
                                        {/*</div>*/}
                                        {/*<div className="col-6">*/}
                                        {salaryFreeze === BOOLEAN_TEXT_TRUE ||
                                        !salaryFreeze ? (
                                            <span>Yes</span>
                                        ) : (
                                            <span>No</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {findReportableData.length > 0 || showSpinner ? (
                                <div></div>
                            ) : (
                                <div className="data-availability">
                                    {TEXT_NO_AVAIL_DATA}
                                </div>
                            )}
                            <div className="pop-up-div printHidden">
                                <Popup
                                    trigger={
                                        <Button
                                            variant="light"
                                            className="column-selection-button"
                                        >
                                            {
                                                REPORT_COLUMN_SELECTION_BUTTON_TEXT
                                            }
                                        </Button>
                                    }
                                    position="bottom center"
                                >
                                    <div className="pop-up-menu">
                                        <CheckboxPercentiles
                                            className="checkbox-container"
                                            type="checkbox"
                                            name="selectAll"
                                            id="selectAll"
                                            handleClick={handleSelectAll}
                                            isChecked={isCheckAll}
                                        />
                                        <label className="percentile-labels">
                                            Include All
                                        </label>
                                        <hr></hr>
                                        {catalog}
                                    </div>
                                </Popup>
                            </div>
                            <div className="tableFixedHead-for-print printVisible">
                                {DisplayData}
                            </div>
                            <div className="tableFixHead printHidden">
                                {DisplayData}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        )
    }
}

export default Region
