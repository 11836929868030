import './App.css'
import Nav from './components/Nav'
import Home from './components/Home'
import Country from './components/Country'
import Practice from './components/Practice'
import Region from './components/Region'
import Historical from './components/Historical'
import PageNotFound from './components/404Page'
import { Routes, Route } from 'react-router-dom'
import React, { useState } from 'react'
import {
    BANNER_SURVEY_NAME_SHORT_NO_CULPEPPER,
    BREAKPOINT_SM,
    CONTACT_US_LINK,
    COOKIE_NOTICE_LINK,
    CURRENT_YEAR,
    CULPEPPER_COME_LINK,
    DATA_CONFIDENTIALITY_LINK,
    PRELIM_SURVEY_LIST,
    PRIVACY_POLICY_LINK,
    SBS_DEV_PAGE_ADDRESS,
    SBS_LOCAL_DEV_PAGE_ADDRESS,
    SURVEY_PUBLISH_DATE_LIST,
    SURVEY_UUID_LIST,
    SURVEY_YEAR_NAME_LIST,
    SBS_PROD_PAGE_ADDRESS,
    DISABLED_ON_PROD_PRELIM_SURVEY_LIST,
    UPCOMING_ON_PROD_PRELIM_SURVEY_LIST,
    UPCOMNING_ON_PROD_FINAL_SURVEY_LIST,
    SURVEY_REDIRECT_LINK_LIST,
} from './constants/constants'
import { REACT_APP_PAGE_ADDRESS } from './config'
import SurveyHome from './components/SurveyHome'
import { useLocation } from 'react-router-dom'
import Participants from './components/Participants'
import PageNotFoundPrelim from './components/404Page_Prelim'
import Definitions from './components/Definitions'

function App() {
    const [pathFullName, setPathFullName] = useState(window.location.pathname)
    const location = useLocation()

    React.useEffect(() => {
        setPathFullName(location.pathname)
    }, [location])

    const useViewport = () => {
        const [width, setWidth] = React.useState(window.innerWidth)

        React.useEffect(() => {
            const handleWindowResize = () => setWidth(window.innerWidth)
            window.addEventListener('resize', handleWindowResize)
            return () =>
                window.removeEventListener('resize', handleWindowResize)
        }, [])

        return { width }
    }

    const { width } = useViewport()

    const pathNameFirstPortion = pathFullName.split('/')[1]

    const survey = SURVEY_UUID_LIST[pathNameFirstPortion]
    const surveyYearName = SURVEY_YEAR_NAME_LIST[survey]

    let isPrelim = PRELIM_SURVEY_LIST.includes(survey)
        ? '(Preliminary Results, ' + SURVEY_PUBLISH_DATE_LIST[survey] + ')'
        : ''

    // Define the RedirectToExternalPage component
    const RedirectToExternalPage = () => {
        React.useEffect(() => {
            window.location.href = SURVEY_REDIRECT_LINK_LIST[survey]
        }, [])

        return null // This component does not render anything
    }

    return (
        <div className="App">
            <div className="content-wrap">
                {pathFullName !== '/' && <Nav />}

                <div className="banner printHidden">
                    {surveyYearName} {BANNER_SURVEY_NAME_SHORT_NO_CULPEPPER}{' '}
                    {width < BREAKPOINT_SM ? <br></br> : <span></span>}
                    <em>{isPrelim}</em>
                </div>

                <Routes>
                    {/*on PROD page, main link go to Culpepper.com, on DEV page, main link go to survey list*/}
                    {[
                        SBS_LOCAL_DEV_PAGE_ADDRESS.replace(/\/$/, ''),
                        SBS_DEV_PAGE_ADDRESS.replace(/\/$/, ''),
                    ].includes(REACT_APP_PAGE_ADDRESS.replace(/\/$/, '')) ? (
                        <Route path="/" element={<SurveyHome />} />
                    ) : (
                        <Route path="/" element={<SiteRedirect />} />
                    )}

                    {PRELIM_SURVEY_LIST.includes(survey) &&
                    [
                        SBS_LOCAL_DEV_PAGE_ADDRESS.replace(/\/$/, ''),
                        SBS_DEV_PAGE_ADDRESS.replace(/\/$/, ''),
                    ].includes(REACT_APP_PAGE_ADDRESS.replace(/\/$/, '')) ? (
                        <>
                            <Route path="/:survey" element={<Home />} />
                            <Route
                                path="/:survey/region"
                                element={<Region />}
                            />
                        </>
                    ) : PRELIM_SURVEY_LIST.includes(survey) &&
                      [SBS_PROD_PAGE_ADDRESS.replace(/\/$/, '')].includes(
                          REACT_APP_PAGE_ADDRESS.replace(/\/$/, '')
                      ) &&
                      DISABLED_ON_PROD_PRELIM_SURVEY_LIST.includes(survey) ? (
                        <>
                            <Route
                                path="/:survey"
                                element={<RedirectToExternalPage />}
                            />
                            <Route
                                path="/:survey/region"
                                element={<RedirectToExternalPage />}
                            />
                        </>
                    ) : PRELIM_SURVEY_LIST.includes(survey) &&
                      [SBS_PROD_PAGE_ADDRESS.replace(/\/$/, '')].includes(
                          REACT_APP_PAGE_ADDRESS.replace(/\/$/, '')
                      ) &&
                      UPCOMING_ON_PROD_PRELIM_SURVEY_LIST.includes(survey) ? (
                        <>
                            <Route path="/:survey" element={<SiteRedirect />} />
                            <Route
                                path="/:survey/region"
                                element={<SiteRedirect />}
                            />
                        </>
                    ) : PRELIM_SURVEY_LIST.includes(survey) &&
                      [SBS_PROD_PAGE_ADDRESS.replace(/\/$/, '')].includes(
                          REACT_APP_PAGE_ADDRESS.replace(/\/$/, '')
                      ) &&
                      !DISABLED_ON_PROD_PRELIM_SURVEY_LIST.includes(survey) &&
                      !UPCOMING_ON_PROD_PRELIM_SURVEY_LIST.includes(survey) ? (
                        <>
                            <Route path="/:survey" element={<Home />} />
                            <Route
                                path="/:survey/region"
                                element={<Region />}
                            />
                        </>
                    ) : UPCOMNING_ON_PROD_FINAL_SURVEY_LIST.includes(survey) &&
                      [SBS_PROD_PAGE_ADDRESS.replace(/\/$/, '')].includes(
                          REACT_APP_PAGE_ADDRESS.replace(/\/$/, '')
                      ) ? (
                        <Route path="*" element={<SiteRedirect />} />
                    ) : (
                        <>
                            <Route path="/:survey" element={<Home />} />
                            <Route
                                path="/:survey/region"
                                element={<Region />}
                            />
                            <Route
                                path="/:survey/country"
                                element={<Country />}
                            />
                            <Route
                                path="/:survey/practice"
                                element={<Practice />}
                            />
                            <Route
                                path="/:survey/participants"
                                element={<Participants />}
                            />

                            <Route
                                path="/:survey/historical"
                                element={<Historical />}
                            />

                            <Route
                                path="/:survey/definitions"
                                element={<Definitions />}
                            />
                        </>
                    )}

                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </div>
            <div className="footer-style printHidden">
                <tr>
                    <td>
                        {' '}
                        © {CURRENT_YEAR} Culpepper and Associates, Inc. All
                        Rights Reserved.{' '}
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                        <a href={CONTACT_US_LINK} className="footer-links">
                            Contact Us
                        </a>{' '}
                        |{' '}
                        <a href={PRIVACY_POLICY_LINK} className="footer-links">
                            Privacy Policy
                        </a>{' '}
                        |{' '}
                        <a
                            href={DATA_CONFIDENTIALITY_LINK}
                            className="footer-links"
                        >
                            Data Confidentiality and Protection
                        </a>{' '}
                        |{' '}
                        <a href={COOKIE_NOTICE_LINK} className="footer-links">
                            Cookie Notice
                        </a>
                    </td>
                </tr>
            </div>
        </div>
    )
}

function SiteRedirect() {
    window.location.replace(CULPEPPER_COME_LINK)
    return <div></div>
}

export default App
