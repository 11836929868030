import {
    TABLE_COLUMN_NAME_GROUP,
    TABLE_COLUMN_NAME_N,
    TABLE_COLUMN_NAME_10th,
    TABLE_COLUMN_NAME_25th,
    TABLE_COLUMN_NAME_33rd,
    TABLE_COLUMN_NAME_40th,
    TABLE_COLUMN_NAME_60th,
    TABLE_COLUMN_NAME_67th,
    TABLE_COLUMN_NAME_75th,
    TABLE_COLUMN_NAME_90th,
    TABLE_COLUMN_NAME_MEAN,
    TABLE_COLUMN_NAME_MEDIAN,
    TABLE_COLUMN_NAME_MODE,
    COLUMN_VALUE_N,
    COLUMN_VALUE_GROUP,
    COLUMN_VALUE_MEAN,
    COLUMN_VALUE_MEDIAN,
    COLUMN_VALUE_MODE,
    COLUMN_VALUE_10th,
    COLUMN_VALUE_25th,
    COLUMN_VALUE_33rd,
    COLUMN_VALUE_40th,
    COLUMN_VALUE_60th,
    COLUMN_VALUE_67th,
    COLUMN_VALUE_75th,
    COLUMN_VALUE_90th,
    POP_UP_MENU_COLUMN_NAME_10th,
    POP_UP_MENU_COLUMN_NAME_25th,
    POP_UP_MENU_COLUMN_NAME_33rd,
    POP_UP_MENU_COLUMN_NAME_40th,
    POP_UP_MENU_COLUMN_NAME_MEDIAN,
    POP_UP_MENU_COLUMN_NAME_60th,
    POP_UP_MENU_COLUMN_NAME_67th,
    POP_UP_MENU_COLUMN_NAME_75th,
    POP_UP_MENU_COLUMN_NAME_90th,
    POP_UP_MENU_COLUMN_NAME_MEAN,
    POP_UP_MENU_COLUMN_NAME_MODE,
    BOOLEAN_TEXT_TRUE,
    BOOLEAN_TEXT_FALSE,
} from './constants'

export const Catalogues = [
    {
        id: COLUMN_VALUE_10th,
        name: POP_UP_MENU_COLUMN_NAME_10th,
    },
    {
        id: COLUMN_VALUE_25th,
        name: POP_UP_MENU_COLUMN_NAME_25th,
        disabled: BOOLEAN_TEXT_TRUE,
    },
    {
        id: COLUMN_VALUE_33rd,
        name: POP_UP_MENU_COLUMN_NAME_33rd,
    },
    {
        id: COLUMN_VALUE_40th,
        name: POP_UP_MENU_COLUMN_NAME_40th,
    },
    {
        id: COLUMN_VALUE_MEDIAN,
        name: POP_UP_MENU_COLUMN_NAME_MEDIAN,
        disabled: BOOLEAN_TEXT_TRUE,
    },
    {
        id: COLUMN_VALUE_60th,
        name: POP_UP_MENU_COLUMN_NAME_60th,
    },
    {
        id: COLUMN_VALUE_67th,
        name: POP_UP_MENU_COLUMN_NAME_67th,
    },
    {
        id: COLUMN_VALUE_75th,
        name: POP_UP_MENU_COLUMN_NAME_75th,
        disabled: BOOLEAN_TEXT_TRUE,
    },
    {
        id: COLUMN_VALUE_90th,
        name: POP_UP_MENU_COLUMN_NAME_90th,
    },
    {
        id: COLUMN_VALUE_MEAN,
        name: POP_UP_MENU_COLUMN_NAME_MEAN,
        disabled: BOOLEAN_TEXT_TRUE,
    },
    {
        id: COLUMN_VALUE_MODE,
        name: POP_UP_MENU_COLUMN_NAME_MODE,
        disabled: BOOLEAN_TEXT_TRUE,
    },
]

export const COLUMNS_TO_SHOW_ALL = [
    {
        name: TABLE_COLUMN_NAME_GROUP,
        value: COLUMN_VALUE_GROUP,
        enabled: BOOLEAN_TEXT_TRUE,
        style: 'td-name',
        percent: '',
    },
    {
        name: TABLE_COLUMN_NAME_N,
        value: COLUMN_VALUE_N,
        enabled: BOOLEAN_TEXT_TRUE,
        style: 'td-grey',
        percent: '',
    },
    {
        name: TABLE_COLUMN_NAME_10th,
        value: COLUMN_VALUE_10th,
        enabled: BOOLEAN_TEXT_FALSE,
        style: 'td-normal',
        percent: '%',
    },
    {
        name: TABLE_COLUMN_NAME_25th,
        value: COLUMN_VALUE_25th,
        enabled: BOOLEAN_TEXT_TRUE,
        style: 'td-normal',
        percent: '%',
    },
    {
        name: TABLE_COLUMN_NAME_33rd,
        value: COLUMN_VALUE_33rd,
        enabled: BOOLEAN_TEXT_FALSE,
        style: 'td-normal',
        percent: '%',
    },
    {
        name: TABLE_COLUMN_NAME_40th,
        value: COLUMN_VALUE_40th,
        enabled: BOOLEAN_TEXT_FALSE,
        style: 'td-normal',
        percent: '%',
    },
    {
        name: TABLE_COLUMN_NAME_MEDIAN,
        value: COLUMN_VALUE_MEDIAN,
        enabled: BOOLEAN_TEXT_TRUE,
        style: 'td-grey',
        percent: '%',
    },
    {
        name: TABLE_COLUMN_NAME_60th,
        value: COLUMN_VALUE_60th,
        enabled: BOOLEAN_TEXT_FALSE,
        style: 'td-normal',
        percent: '%',
    },
    {
        name: TABLE_COLUMN_NAME_67th,
        value: COLUMN_VALUE_67th,
        enabled: BOOLEAN_TEXT_FALSE,
        style: 'td-normal',
        percent: '%',
    },
    {
        name: TABLE_COLUMN_NAME_75th,
        value: COLUMN_VALUE_75th,
        enabled: BOOLEAN_TEXT_TRUE,
        style: 'td-normal',
        percent: '%',
    },
    {
        name: TABLE_COLUMN_NAME_90th,
        value: COLUMN_VALUE_90th,
        enabled: BOOLEAN_TEXT_FALSE,
        style: 'td-normal',
        percent: '%',
    },
    {
        name: TABLE_COLUMN_NAME_MEAN,
        value: COLUMN_VALUE_MEAN,
        enabled: BOOLEAN_TEXT_TRUE,
        style: 'td-grey',
        percent: '%',
    },
    {
        name: TABLE_COLUMN_NAME_MODE,
        value: COLUMN_VALUE_MODE,
        enabled: BOOLEAN_TEXT_TRUE,
        style: 'td-grey',
        percent: '%',
    },
]
