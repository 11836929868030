import {
    SURVEY_YEAR_2022_2023,
    SURVEY_YEAR_2023,
    SURVEY_YEAR_2023_2024,
    SURVEY_YEAR_2024,
    SURVEY_YEAR_2024_2025,
} from './constants'

export const REPORT_BASE_SALARY_INCREASE = 'Base Salary Increases'
export const HISTORICAL_REPORT_BASE_SALARY_INCREASE =
    'Historical Average (Mean) Base Salary Increases'
export const REPORT_BASE_SALARY_INCREASE_BY_INDUSTRY_SECTOR =
    'Base Salary Increases by Industry Sector'
export const REPORT_BASE_SALARY_INCREASE_BY_INDUSTRY_VERTICAL =
    'Base Salary Increases by Industry Vertical'
export const REPORT_BASE_SALARY_INCREASE_BY_OWNERSHIP =
    'Base Salary Increases by Ownership'
export const REPORT_BASE_SALARY_INCREASE_BY_HEADCOUNT =
    'Base Salary Increases by Headcount'

export const REPORT_BASE_SALARY_INCREASE_BY_LOCALE =
    'Base Salary Increases by Geo Locale'

export const REPORT_BASE_SALARY_INCREASE_BY_PAYZONE =
    'Base Salary Increases by Geo Pay Zone'

const REPORT_BASE_SALARY_INCREASE_BY_JOB_LEVEL_FUNCTION =
    'Base Salary Increases by Job Level and Function'
const REPORT_BASE_SALARY_INCREASE_BY_JOB_LEVEL =
    'Base Salary Increases by Job Level'
const REPORT_BASE_SALARY_INCREASE_BY_JOB_FUNCTION =
    'Base Salary Increases by Job Function'
const REPORT_BASE_SALARY_INCREASE_BY_PER =
    'Base Salary Increases by Performance'
const REPORT_BASE_SALARY_INCREASE_BY_POSITION_IN_RANGE =
    'Base Salary Increases by Position-in-Range'
const REPORT_BASE_SALARY_INCREASE_BY_COMPA_RATIO =
    'Base Salary Increases by Compa-Ratio'
const REPORT_BASE_SALARY_STRUCTURE_ADJUSTMENTS =
    'Base Salary Structure Adjustments'
const REPORT_PROMOTIONAL_INCREASES = 'Promotional Increases'
const REPORT_STI_NON_SALES = 'Short-Term Cash Incentives'

export const PAY_ZONED_COUNTRIES = [
    'United States',
    'Canada',
    'India',
    'China',
    'United Kingdom',
]

export const ADVANCED_BSI_REPORT_TYPES = [
    REPORT_BASE_SALARY_INCREASE_BY_JOB_LEVEL,
    REPORT_BASE_SALARY_INCREASE_BY_JOB_FUNCTION,
    REPORT_BASE_SALARY_INCREASE_BY_JOB_LEVEL_FUNCTION,
    REPORT_BASE_SALARY_INCREASE_BY_PER,
    REPORT_BASE_SALARY_INCREASE_BY_POSITION_IN_RANGE,
    REPORT_BASE_SALARY_INCREASE_BY_COMPA_RATIO,
    REPORT_BASE_SALARY_STRUCTURE_ADJUSTMENTS,
    REPORT_PROMOTIONAL_INCREASES,
    REPORT_STI_NON_SALES,
    REPORT_BASE_SALARY_INCREASE_BY_PAYZONE,
    REPORT_BASE_SALARY_INCREASE_BY_LOCALE,
]

const GEO_REPORT_TYPES = [
    {
        label: 'Salary Increases by Geo Locale',
        value: REPORT_BASE_SALARY_INCREASE_BY_LOCALE,
    },
    {
        label: 'Salary Increases by Geo Pay Zone',
        value: REPORT_BASE_SALARY_INCREASE_BY_PAYZONE,
    },
]

const BASIC_REPORT_TYPES = [
    {
        label: 'Salary Increases for Country',
        value: REPORT_BASE_SALARY_INCREASE,
    },
    {
        label: 'Salary Increases by Industry Sector',
        value: REPORT_BASE_SALARY_INCREASE_BY_INDUSTRY_SECTOR,
    },
    {
        label: 'Salary Increases by Industry Vertical',
        value: REPORT_BASE_SALARY_INCREASE_BY_INDUSTRY_VERTICAL,
    },
    {
        label: 'Salary Increases by Ownership',
        value: REPORT_BASE_SALARY_INCREASE_BY_OWNERSHIP,
    },
    {
        label: 'Salary Increases by Headcount',
        value: REPORT_BASE_SALARY_INCREASE_BY_HEADCOUNT,
    },
]

const OTHER_REPORT_TYPES_2023_AND_PRIOR = [
    {
        label: 'Salary Structure Adjustments',
        value: REPORT_BASE_SALARY_STRUCTURE_ADJUSTMENTS,
    },
    {
        label: 'Promotional Increases',
        value: REPORT_PROMOTIONAL_INCREASES,
    },
    {
        label: 'Short-Term Cash Incentives',
        value: REPORT_STI_NON_SALES,
    },
]
const OTHER_REPORT_TYPES_AFTER_2023 = [
    {
        label: 'Salary Structure Adjustments',
        value: REPORT_BASE_SALARY_STRUCTURE_ADJUSTMENTS,
    },
    {
        label: 'Promotional Increases',
        value: REPORT_PROMOTIONAL_INCREASES,
    },
]

const JOB_LEVEL_FUNCTION_REPORT_TYPES_2023_AND_PRIOR = [
    {
        label: 'Salary Increases by Job Level and Function',
        value: REPORT_BASE_SALARY_INCREASE_BY_JOB_LEVEL_FUNCTION,
    },
    {
        label: 'Salary Increases by Performance',
        value: REPORT_BASE_SALARY_INCREASE_BY_PER,
    },
]

const JOB_LEVEL_FUNCTION_REPORT_TYPES_2024_2025 = [
    {
        label: 'Salary Increases by Job Level and Function',
        value: REPORT_BASE_SALARY_INCREASE_BY_JOB_LEVEL_FUNCTION,
    },
    {
        label: 'Salary Increases by Performance',
        value: REPORT_BASE_SALARY_INCREASE_BY_PER,
    },
    {
        label: 'Salary Increases by Position-in-Range',
        value: REPORT_BASE_SALARY_INCREASE_BY_POSITION_IN_RANGE,
    },
    {
        label: 'Salary Increases by Compa-Ratio',
        value: REPORT_BASE_SALARY_INCREASE_BY_COMPA_RATIO,
    },
]

const JOB_LEVEL_FUNCTION_REPORT_TYPES_AFTER_2023 = [
    {
        label: 'Salary Increases by Job Level',
        value: REPORT_BASE_SALARY_INCREASE_BY_JOB_LEVEL,
    },
    {
        label: 'Salary Increases by Job Function',
        value: REPORT_BASE_SALARY_INCREASE_BY_JOB_FUNCTION,
    },
    {
        label: 'Salary Increases by Performance',
        value: REPORT_BASE_SALARY_INCREASE_BY_PER,
    },
]

const BASE_SALARY_INCREASES_REPORT_GROUP = 'Base Salary Increases'
const OTHER_INCREASES_REPORT_GROUP = 'Other Increases'

const REPORT_OPTIONS_PAY_ZONED_COUNTRIES_ALL_2023_AND_PRIOR = [
    {
        label: BASE_SALARY_INCREASES_REPORT_GROUP,
        options: BASIC_REPORT_TYPES.concat(GEO_REPORT_TYPES).concat(
            JOB_LEVEL_FUNCTION_REPORT_TYPES_2023_AND_PRIOR
        ),
    },
    {
        label: OTHER_INCREASES_REPORT_GROUP,
        options: OTHER_REPORT_TYPES_2023_AND_PRIOR,
    },
]

const REPORT_OPTIONS_PAY_ZONED_COUNTRIES_2023_2024 = [
    {
        label: BASE_SALARY_INCREASES_REPORT_GROUP,
        options: BASIC_REPORT_TYPES.concat(GEO_REPORT_TYPES).concat(
            JOB_LEVEL_FUNCTION_REPORT_TYPES_AFTER_2023
        ),
    },
    {
        label: OTHER_INCREASES_REPORT_GROUP,
        options: OTHER_REPORT_TYPES_AFTER_2023,
    },
]

const REPORT_OPTIONS_PAY_ZONED_COUNTRIES_2024 = [
    {
        label: BASE_SALARY_INCREASES_REPORT_GROUP,
        options: BASIC_REPORT_TYPES.concat(GEO_REPORT_TYPES).concat(
            JOB_LEVEL_FUNCTION_REPORT_TYPES_AFTER_2023
        ),
    },
    {
        label: OTHER_INCREASES_REPORT_GROUP,
        options: OTHER_REPORT_TYPES_2023_AND_PRIOR,
    },
]

const REPORT_OPTIONS_PAY_ZONED_COUNTRIES_2024_2025 = [
    {
        label: BASE_SALARY_INCREASES_REPORT_GROUP,
        options: BASIC_REPORT_TYPES.concat(GEO_REPORT_TYPES).concat(
            JOB_LEVEL_FUNCTION_REPORT_TYPES_2024_2025
        ),
    },
    {
        label: OTHER_INCREASES_REPORT_GROUP,
        options: OTHER_REPORT_TYPES_2023_AND_PRIOR,
    },
]

const REPORT_OPTIONS_PAY_ZONED_COUNTRIES_BASIC_OPTIONS = [
    {
        label: BASE_SALARY_INCREASES_REPORT_GROUP,
        options: BASIC_REPORT_TYPES.concat(GEO_REPORT_TYPES),
    },
]

const REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_ALL_2023_AND_PRIOR = [
    {
        label: BASE_SALARY_INCREASES_REPORT_GROUP,
        options: BASIC_REPORT_TYPES.concat(
            JOB_LEVEL_FUNCTION_REPORT_TYPES_2023_AND_PRIOR
        ),
    },
    {
        label: OTHER_INCREASES_REPORT_GROUP,
        options: OTHER_REPORT_TYPES_2023_AND_PRIOR,
    },
]

const REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_ALL_AFTER_2023 = [
    {
        label: BASE_SALARY_INCREASES_REPORT_GROUP,
        options: BASIC_REPORT_TYPES.concat(
            JOB_LEVEL_FUNCTION_REPORT_TYPES_AFTER_2023
        ),
    },
    {
        label: OTHER_INCREASES_REPORT_GROUP,
        options: OTHER_REPORT_TYPES_AFTER_2023,
    },
]

const REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_ALL_2024_2025 = [
    {
        label: BASE_SALARY_INCREASES_REPORT_GROUP,
        options: BASIC_REPORT_TYPES.concat(
            JOB_LEVEL_FUNCTION_REPORT_TYPES_2024_2025
        ),
    },
    {
        label: OTHER_INCREASES_REPORT_GROUP,
        options: OTHER_REPORT_TYPES_AFTER_2023,
    },
]

const REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_BASIC_OPTIONS = [
    {
        label: BASE_SALARY_INCREASES_REPORT_GROUP,
        options: BASIC_REPORT_TYPES,
    },
]

export const REPORT_OPTIONS_PAY_ZONED_COUNTRIES_ALL = {
    [SURVEY_YEAR_2022_2023]:
        REPORT_OPTIONS_PAY_ZONED_COUNTRIES_ALL_2023_AND_PRIOR,
    [SURVEY_YEAR_2023]: REPORT_OPTIONS_PAY_ZONED_COUNTRIES_ALL_2023_AND_PRIOR,
    [SURVEY_YEAR_2023_2024]: REPORT_OPTIONS_PAY_ZONED_COUNTRIES_2023_2024,
    [SURVEY_YEAR_2024]: REPORT_OPTIONS_PAY_ZONED_COUNTRIES_2024,
    [SURVEY_YEAR_2024_2025]: REPORT_OPTIONS_PAY_ZONED_COUNTRIES_2024_2025,
}

export const REPORT_OPTIONS_PAY_ZONED_COUNTRIES_BASIC = {
    [SURVEY_YEAR_2022_2023]: REPORT_OPTIONS_PAY_ZONED_COUNTRIES_BASIC_OPTIONS,
    [SURVEY_YEAR_2023]: REPORT_OPTIONS_PAY_ZONED_COUNTRIES_BASIC_OPTIONS,
    [SURVEY_YEAR_2023_2024]: REPORT_OPTIONS_PAY_ZONED_COUNTRIES_BASIC_OPTIONS,
    [SURVEY_YEAR_2024]: REPORT_OPTIONS_PAY_ZONED_COUNTRIES_BASIC_OPTIONS,
    [SURVEY_YEAR_2024_2025]: REPORT_OPTIONS_PAY_ZONED_COUNTRIES_BASIC_OPTIONS,
}

export const REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_ALL = {
    [SURVEY_YEAR_2022_2023]:
        REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_ALL_2023_AND_PRIOR,
    [SURVEY_YEAR_2023]:
        REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_ALL_2023_AND_PRIOR,
    [SURVEY_YEAR_2023_2024]:
        REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_ALL_AFTER_2023,
    [SURVEY_YEAR_2024]: REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_ALL_AFTER_2023,
    [SURVEY_YEAR_2024_2025]:
        REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_ALL_2024_2025,
}

export const REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_BASIC = {
    [SURVEY_YEAR_2022_2023]:
        REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_BASIC_OPTIONS,
    [SURVEY_YEAR_2023]: REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_BASIC_OPTIONS,
    [SURVEY_YEAR_2023_2024]:
        REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_BASIC_OPTIONS,
    [SURVEY_YEAR_2024]: REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_BASIC_OPTIONS,
    [SURVEY_YEAR_2024_2025]:
        REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_BASIC_OPTIONS,
}

export const COUNTRY_SHOW_QUESTION = [
    {
        report: 'Base Salary Increases by Position-in-Range',
        year: 2024,
        question:
            'For each of the following positions-in-range within a pay band, what is the average base salary increase percentage for 2024 in your organization?',
    },
    {
        report: 'Base Salary Increases by Position-in-Range',
        year: 2025,
        question:
            'For each of the following positions-in-range within a pay band, what is the average expected base salary increase percentage for 2025 in your organization?',
    },
    {
        report: 'Base Salary Increases by Compa-Ratio',
        year: 2024,
        question:
            'For each of the following compa-ratios, what is the average base salary increase percentage for 2024 in your organization?',
    },
    {
        report: 'Base Salary Increases by Compa-Ratio',
        year: 2025,
        question:
            'For each of the following compa-ratios, what is the average expected base salary increase percentage for 2025 in your organization?',
    },
]
