import React from 'react'
import Axis from './axis.js'
import { transition } from 'd3-transition'
import { scaleLinear, scaleBand } from 'd3-scale'

const XYAxis = ({ data, width, height, margin, transitionTime, maxValue }) => {
    const innerWidth = width - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom
    const ticks = 6
    const t = transition().duration(transitionTime)

    let xScale
    // if maxValue is between 0 and 30, then xScale = scaleLinear().domain([0, 30]).range([0, innerWidth]).nice(), else if maxValue is between 30 and 50, then xScale = scaleLinear().domain([0, 50]).range([0, innerWidth]).nice(), else if maxValue is between 50 and 80, then xScale = scaleLinear().domain([0, 80]).range([0, innerWidth]).nice(), else if maxValue is between 80 and 100, then xScale = scaleLinear().domain([0, 100]).range([0, innerWidth]).nice()
    if (maxValue <= 30) {
        xScale = scaleLinear().domain([0, 30]).range([0, innerWidth]).nice()
    } else if (maxValue <= 50) {
        xScale = scaleLinear().domain([0, 50]).range([0, innerWidth]).nice()
    } else if (maxValue <= 80) {
        xScale = scaleLinear().domain([0, 80]).range([0, innerWidth]).nice()
    } else {
        xScale = scaleLinear().domain([0, 100]).range([0, innerWidth]).nice()
    }

    const yScale = scaleBand()
        .domain(data.map((d) => d.genre))
        .range([0, innerHeight])
        .padding(0.26)

    const xSettings = {
        scale: xScale,
        orient: 'bottom',
        transform: `translate(${margin.left}, ${innerHeight + margin.top})`,
        t,
    }

    const ySettings = {
        scale: yScale,
        orient: 'left',
        transform: `translate(${margin.left}, ${margin.top})`,
        ticks,
        t,
        margin: margin.left,
    }

    return (
        <g className="axis-group">
            <Axis {...xSettings} />
            <Axis {...ySettings} />
        </g>
    )
}

export default XYAxis
