export const REGIONS = [
    'North America',
    'Latin America',
    'Europe',
    'Asia',
    'Africa',
    'Oceania',
]

export const REGIONS_ASIA_EUROPE = ['Europe', 'Asia']
export const REGION_GLOBAL = 'Global'
export const SUB_REGIONS = [
    'Central America',
    'Caribbean',
    'South America',
    'European Union',
    'Europe (Non-European Union)',
    'Middle East & West Asia',
    'Central & North Asia',
    'Central Asia',
    'South & East Asia',
    'North Africa',
    'Sub-Saharan Africa',
    'Central Africa','East Africa','North Africa','Southern Africa','West Africa',
]

export const SUB_REGIONS_FOR_GLOBAL_SELECTION = [
    'European Union',
    'Europe (Non-European Union)',
    'Middle East & West Asia',
    'Central & North Asia',
    'Central Asia',
    'South & East Asia',
]

export const MINI_REGIONS = [
    'European Union (Eurozone)',
    'European Union (Non-Eurozone)',
    'Middle East',
    'West Asia',
    'South Asia',
    'East Asia',
    'Southeast Asia',
]

export const REGION_OPTIONS = [
    { label: 'Global', value: 'Global' },
    { label: 'North America', value: 'North America' },
    { label: 'Latin America', value: 'Latin America' },
    { label: 'Europe', value: 'Europe' },
    { label: 'Asia', value: 'Asia' },
    { label: 'Africa', value: 'Africa' },
    { label: 'Oceania', value: 'Oceania' },
]
