import {
    BOOLEAN_TEXT_FALSE,
    BOOLEAN_TEXT_TRUE,
    CURRENT_YEAR_TEXT,
    DICT_KEY_COUNT,
    DICT_KEY_PERCENT,
    FOOTER_COLUMN_SPAN_NUMBER,
    PRELIM_SURVEY_LIST,
    SOURCE_CULPEPPER_ANNOTATION_TEXT,
    SOURCE_PRELIM_ANNOTATION_TEXT,
    SURVEY_COPY_RIGHT,
    SURVEY_COPY_RIGHT_HISTORY,
    SURVEY_PUBLISH_DATE_LIST,
    SURVEY_PUBLISH_MONTH_LIST,
    SURVEY_TITLE_TEXT,
    SURVEY_YEAR_NAME_LIST,
    TABLE_COLUMN_NAME_33rd,
    TABLE_COLUMN_NAME_GROUP,
    TABLE_COLUMN_NAME_MEAN,
    TABLE_COLUMN_NAME_MODE,
    TABLE_COLUMN_NAME_N,
    TEXT_ANNOTATION_FOR_N,
    TEXT_INSUFFICIENT_DATA,
    UPCOMING_YEAR_TEXT,
} from '../constants/constants'
import * as d3 from 'd3'
import * as qs from 'qs'
import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import React from 'react'
import {
    MINI_REGIONS,
    REGION_GLOBAL,
    REGIONS,
    REGIONS_ASIA_EUROPE,
    SUB_REGIONS,
    SUB_REGIONS_FOR_GLOBAL_SELECTION,
} from '../constants/regionConstants'
import Select from 'react-select'
import { customFontStyles } from '../docs/style'
import {
    HEADCOUNT_OPTIONS,
    OWNERSHIP_GROUPS,
    SECTOR_GROUPS,
    STARTUP_OPTIONS,
    VERTICALS,
    ALL_DATA_CUT_OPTIONS,
} from '../constants/datacutConstants'
export default function wrap(textElements, width) {
    textElements.each(function () {
        var text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            // lineNumber = 0,
            // lineHeight = 1.1, // ems
            y = text.attr('y'),
            dy = parseFloat(text.attr('dy')),
            tspan = text
                .text(null)
                .append('tspan')
                .attr('x', -10)
                .attr('y', y)
                .attr('dy', dy + 'em')
        while ((word = words.pop())) {
            line.push(word)
            tspan.text(line.join(' '))
            if (tspan.node().getComputedTextLength() > width) {
                line.pop()
                tspan.text(line.join(' '))
                line = [word]
                tspan = text
                    .append('tspan')
                    .attr('x', -10)
                    .attr('y', y)
                    // .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                    .attr('dy', '1em')
                    .text(word)
            }
        }
    })
}

export const useQueryState = (query) => {
    const location = useLocation()
    const navigate = useNavigate()

    const setQuery = useCallback(
        (value) => {
            const existingQueries = qs.parse(location.search, {
                ignoreQueryPrefix: true,
            })

            const queryString = qs.stringify(
                { ...existingQueries, [query]: value },
                { skipNulls: true }
            )

            navigate(`${location.pathname}?${queryString}`)
        },
        [navigate, location, query]
    )

    return [
        qs.parse(location.search, { ignoreQueryPrefix: true })[query],
        setQuery,
    ]
}

export const getTotalResponse = (a_list) => {
    let totalResponse

    if (a_list.length === 0) {
        totalResponse = 0
    } else {
        totalResponse =
            (a_list[0][DICT_KEY_COUNT] / a_list[0][DICT_KEY_PERCENT]) * 100
    }

    return Number(totalResponse.toFixed(0))
}

export const updateSectorSelectedValueForDataCutLables = (text) => {
    if (text) {
        // loop through each object in list ALL_DATA_CUT_OPTIONS and if the object's value matches the text, return the object's label
        for (let i = 0; i < ALL_DATA_CUT_OPTIONS.length; i++) {
            if (ALL_DATA_CUT_OPTIONS[i].value === text) {
                return ALL_DATA_CUT_OPTIONS[i].label
            }
        }
    } else {
        return text
    }
}

const getNonPercentilesTableHeaderNameStyle = (column) => {
    return <span>{column.replaceAll(' ', '\n')}</span>
}

const getPercentilesTableHeaderNameStyle = (column, serperator) => {
    return (
        <span>
            {column.replaceAll(' ', '\n').split(serperator)[0]}
            <sup>{serperator}</sup>
            {column.replaceAll(' ', '\n').split(serperator)[1]}
        </span>
    )
}

export const getTableHeaderStyle = (column) => {
    if (column === TABLE_COLUMN_NAME_GROUP) {
        return <div></div>
    } else if ([TABLE_COLUMN_NAME_N, TABLE_COLUMN_NAME_MODE].includes(column)) {
        return (
            <div className="vertical-align">
                {getNonPercentilesTableHeaderNameStyle(column)}
            </div>
        )
    } else if (column === TABLE_COLUMN_NAME_MEAN) {
        return (
            <div className="bottom-align">{column.replaceAll(' ', '\n')}</div>
        )
    } else {
        if (column === TABLE_COLUMN_NAME_33rd) {
            return (
                <div className="bottom-align">
                    {getPercentilesTableHeaderNameStyle(column, 'rd')}
                </div>
            )
        } else {
            return (
                <div className="bottom-align">
                    {getPercentilesTableHeaderNameStyle(column, 'th')}
                </div>
            )
        }
    }
}

export function getDataCutSelectionTextTitle(selectedValue, label) {
    return (
        <div>
            {selectedValue ? (
                <span className="datacut-type">{label}</span>
            ) : (
                <span></span>
            )}
        </div>
    )
}

export function getDataCutSelectionTextName(selectedValue) {
    return (
        <div>
            {selectedValue ? (
                <span>
                    {updateSectorSelectedValueForDataCutLables(selectedValue)}
                </span>
            ) : (
                <span></span>
            )}
        </div>
    )
}

export function cellStyle(region, row, column) {
    // if 'Global' is selected
    if (region === REGION_GLOBAL) {
        if (REGIONS.includes(row[TABLE_COLUMN_NAME_GROUP].toString())) {
            if (REGIONS.includes(row[column.value].toString())) {
                return 'td-all-data-name'
            } else {
                return 'td-all-data-numbers'
            }
        } else if (
            SUB_REGIONS_FOR_GLOBAL_SELECTION.includes(
                row[TABLE_COLUMN_NAME_GROUP].toString()
            )
        ) {
            if (
                SUB_REGIONS_FOR_GLOBAL_SELECTION.includes(
                    row[column.value].toString()
                )
            ) {
                return 'td-sub-group-name'
            } else {
                return 'td-sub-group-numbers'
            }
        } else {
            return column.style
        }
        // if 'Asia' or 'Europe' is selected
    } else if (REGIONS_ASIA_EUROPE.includes(region)) {
        if (
            REGIONS_ASIA_EUROPE.includes(
                row[TABLE_COLUMN_NAME_GROUP].toString()
            )
        ) {
            if (REGIONS_ASIA_EUROPE.includes(row[column.value].toString())) {
                return 'td-all-data-name'
            } else {
                return 'td-all-data-numbers'
            }
        } else if (
            SUB_REGIONS_FOR_GLOBAL_SELECTION.includes(
                row[TABLE_COLUMN_NAME_GROUP].toString()
            )
        ) {
            if (
                SUB_REGIONS_FOR_GLOBAL_SELECTION.includes(
                    row[column.value].toString()
                )
            ) {
                return 'td-group-name'
            } else {
                return 'td-group-numbers'
            }
        } else if (
            MINI_REGIONS.includes(row[TABLE_COLUMN_NAME_GROUP].toString())
        ) {
            if (MINI_REGIONS.includes(row[column.value].toString())) {
                return 'td-sub-group-name'
            } else {
                return 'td-sub-group-numbers'
            }
        } else {
            return column.style
        }
    } else {
        if (REGIONS.includes(row[TABLE_COLUMN_NAME_GROUP].toString())) {
            if (REGIONS.includes(row[column.value].toString())) {
                return 'td-all-data-name'
            } else {
                return 'td-all-data-numbers'
            }
        } else if (
            SUB_REGIONS.includes(row[TABLE_COLUMN_NAME_GROUP].toString())
        ) {
            if (SUB_REGIONS.includes(row[column.value].toString())) {
                return 'td-sub-group-name'
            } else {
                return 'td-sub-group-numbers'
            }
        } else {
            return column.style
        }
    }
}

export const ReactSelect = ({
    label,
    annotation,
    className,
    options,
    onChange,
    value,
    isDisabled,
    isMulti,
    formatGroupLabel,
    isClearable,
    isLoading,
    placeholder,
    closeMenuOnSelect,
}) => {
    return (
        <div>
            <p className="report-seperator">{label}</p>
            <span>{annotation}</span>
            <Select
                annotation={annotation}
                className={className}
                options={options}
                onChange={onChange}
                value={value}
                styles={customFontStyles}
                maxMenuHeight="500px"
                menuPlacement="auto"
                menuPortalTarget={document.body}
                placeholder={placeholder}
                formatGroupLabel={formatGroupLabel}
                isDisabled={isDisabled}
                isMulti={isMulti}
                isClearable={isClearable}
                isSearchable={true}
                isLoading={isLoading}
                closeMenuOnSelect={closeMenuOnSelect}
            />
        </div>
    )
}

export const Checkbox = ({ label, value, onChange, id, disabled, title }) => {
    return (
        <label className="filter-labels">
            <input
                type="checkbox"
                checked={value}
                onChange={onChange}
                disabled={disabled}
                id={id}
                title={title}
            />
            {label}
        </label>
    )
}

export const CheckboxPercentiles = ({
    id,
    type,
    name,
    handleClick,
    isChecked,
    disabled,
}) => {
    return (
        <input
            id={id}
            name={name}
            type={type}
            onChange={handleClick}
            checked={isChecked}
            disabled={disabled}
        />
    )
}

export const RadioButton = ({ label, value, onChange, id, disabled }) => {
    return (
        <label className="filter-labels">
            <input
                type="radio"
                checked={value}
                onChange={onChange}
                disabled={disabled}
                id={id}
            />
            {label}
        </label>
    )
}
export function IsValidDataCutSelection(
    sector,
    vertical,
    ownership,
    headcount,
    startup,
    headcountOptions
) {
    if (
        (sector &&
            SECTOR_GROUPS.reduce(
                (acc, val) => acc.concat(val.options),
                []
            ).find((c) => c.value === sector) === undefined) ||
        (vertical &&
            VERTICALS.find((c) => c.value === vertical) === undefined) ||
        (ownership &&
            OWNERSHIP_GROUPS.reduce(
                (acc, val) => acc.concat(val.options),
                []
            ).find((c) => c.value === ownership) === undefined) ||
        (headcount &&
            headcountOptions.find((c) => c.value === headcount) ===
                undefined) ||
        (startup &&
            STARTUP_OPTIONS.find((c) => c.value === startup) === undefined)
    ) {
        return true
    }
}

export function IsValidOtherOptions(salaryFreeze, showNonReportableData, year) {
    if (
        (salaryFreeze &&
            salaryFreeze !== BOOLEAN_TEXT_TRUE &&
            salaryFreeze !== BOOLEAN_TEXT_FALSE) ||
        (showNonReportableData &&
            showNonReportableData !== BOOLEAN_TEXT_TRUE &&
            showNonReportableData !== BOOLEAN_TEXT_FALSE) ||
        (year && year !== CURRENT_YEAR_TEXT && year !== UPCOMING_YEAR_TEXT)
    ) {
        return true
    }
}

export const tableFooter = (
    survey,
    showN,
    historical,
    showPayZoneNotes,
    text
) => {
    return (
        <tfoot>
            <tr>
                <td
                    className="footer-insufficient-data"
                    colSpan={FOOTER_COLUMN_SPAN_NUMBER}
                >
                    {' '}
                    {TEXT_INSUFFICIENT_DATA}
                </td>
            </tr>
            {showN === 'yes' ? (
                <tr>
                    <td
                        className="footer-insufficient-data"
                        colSpan={FOOTER_COLUMN_SPAN_NUMBER}
                    >
                        {text}
                    </td>
                </tr>
            ) : (
                <tr></tr>
            )}
            {showPayZoneNotes === true ? (
                <tr>
                    <td
                        className="footer-insufficient-data"
                        colSpan={FOOTER_COLUMN_SPAN_NUMBER}
                    >
                        Culpepper Geographic Pay Zones: Culpepper Geographic Pay
                        Zones combine data from geographic locations and postal
                        codes with similar pay rates. Pay Zones span the
                        highest-paying areas (Pay Zone 1) through the
                        lowest-paying areas (Pay Zone 5).
                    </td>
                </tr>
            ) : (
                <tr></tr>
            )}
            <tr>
                <td
                    className="footer-insufficient-data"
                    colSpan={FOOTER_COLUMN_SPAN_NUMBER}
                >
                    <span>
                        {/* Source: {SURVEY_YEAR_NAME_LIST[survey]}{' '}
                        {SURVEY_TITLE_TEXT} */}
                        {historical === 'yes' ? (
                            <span>{SURVEY_COPY_RIGHT_HISTORY[survey]}</span>
                        ) : (
                            <span>
                                {SURVEY_COPY_RIGHT[survey]}
                                {/* {PRELIM_SURVEY_LIST.includes(survey) ? (
                                    <span>
                                        <span>
                                            {' '}
                                            ({
                                                SOURCE_PRELIM_ANNOTATION_TEXT
                                            },{' '}
                                            {SURVEY_PUBLISH_DATE_LIST[survey]})
                                        </span>
                                    </span>
                                ) : (
                                    <span>
                                        , {SURVEY_PUBLISH_MONTH_LIST[survey]}{' '}
                                    </span>
                                )}{' '} */}
                            </span>
                        )}
                    </span>
                    {/* {SOURCE_CULPEPPER_ANNOTATION_TEXT} */}
                </td>
            </tr>
        </tfoot>
    )
}
