import {
    SURVEY_YEAR_2022_2023,
    SURVEY_YEAR_2023,
    SURVEY_YEAR_2023_PRELIM,
    SURVEY_YEAR_2023_2024,
    SURVEY_YEAR_2024,
    SURVEY_YEAR_2024_2025,
} from './constants'

const PRACTICE_REPORT_2022_2023_OPTIONS = [
    {
        label: 'Base Pay Philosophy',
        value: 'BasePayPhilosophy',
        title: 'Base Pay Philosophy',
        question:
            'What is your organization’s base pay philosophy for most employees?',
    },
    {
        label: 'Frequency of Base Pay Increases',
        value: 'ReviewFrequency_Select',
        title: 'Frequency of Base Pay increases',
        question:
            'How frequently does your organization typically increase base salaries and hourly wages for most employees?',
    },
    {
        label: 'Prevalence of Salary Structures',
        value: 'Structure',
        title: 'Prevalence of Salary Structures',
        question:
            'Does your organization have formal base salary ranges, structures, or grades? Exclude executive and sales employees from your response.',
    },
    {
        label: 'Frequency of Salary Structure Adjustments',
        value: 'StructureFrequencySelect',
        title: 'Frequency of Salary Structure Adjustments',
        question:
            'How frequently does your organization typically adjust base salary structures for most employees?',
    },
    {
        label: 'Salary Budgets by Geo Location',
        value: 'BasePayGeo_diffBudgets',
        title: 'Base Salary Budgets by Geographic Location',
        question:
            'Does your organization have different salary budgets by geographic location?',
    },
    {
        label: 'Types of Base Pay Increases',
        value: 'BasePayIncreaseType',
        title: 'Types of Base Pay Increases Provided',
        question:
            'When determining base pay increases, what types of non-promotional base pay increases do you typically provide to most employees?',
    },

    {
        label: 'Impact of Inflation',
        value: 'InflationOnSalaryIncreaseFrequency_Select',
        title: 'Impact of Inflation on the Timing of Salary Increases',
        question:
            'How have recent changes in inflation impacted the timing of salary increases?',
    },

    {
        label: 'Other Compensation Plans',
        value: 'TotalRewards_Select',
        title: 'Other Compensation Plans & Practices',
        question:
            'In addition to base pay, what other cash and equity incentive plans does your organization use or plan to use within the next year to attract, retain, and reward employees?',
    },
]

const PRACTICE_REPORT_2023_2024_OPTIONS = [
    {
        label: 'Frequency of Base Pay Increases',
        value: 'ReviewFrequency_Select',
        title: 'Frequency of Base Pay increases',
        question:
            'How frequently does your organization typically give base pay increases to employees?',
    },
    {
        label: 'Prevalence of Salary Structures',
        value: 'Structure',
        title: 'Prevalence of Salary Structures',
        question:
            'Does your organization have formal salary structures with ranges or grades for employees?',
    },
    {
        label: 'Frequency of Salary Structure Adjustments',
        value: 'StructureFrequencySelect',
        title: 'Frequency of Salary Structure Adjustments',
        question:
            'How frequently does your organization typically adjust base salary structures?',
    },
    {
        label: 'Prevalence of Short-Term Cash Incentives',
        value: 'STI_Choice',
        title: 'Prevalence of Short-Term Cash Incentives',
        question:
            'Does your organization currently use or plan to use short-term cash incentives to reward employees?',
    },
    {
        label: 'Short-Term Cash Incentive Budgets',
        value: 'STIPercent',
        title: 'Short-Term Cash Incentive Budgets',
        question:
            'Approximately what percentage of your total payroll is budgeted/projected for short-term cash incentives?',
    },
    {
        label: 'Types of Short-Term Cash Incentives',
        value: 'STI_Type',
        title: 'Types of Short-Term Cash Incentives',
        question:
            'Which of the following types of short-term cash incentives does your organization use or plan to use within the next year?',
    },
    {
        label: 'Prevalence of Long-Term Incentives',
        value: 'LTI_Choice',
        title: 'Prevalence of Long-Term Incentives',
        question:
            'Does your organization currently use or plan to use equity compensation or any type of long-term incentive to reward employees?',
    },
    {
        label: 'Types of Long-Term Incentives',
        value: 'LTI_Type',
        title: 'Types of Long-Term Incentives',
        question:
            'Which of the following types of long-term incentives does your organization use or plan to use within the next year?',
    },
]

const PRACTICE_REPORT_2024_OPTIONS = [
    {
        label: 'Frequency of Base Pay Increases',
        value: 'ReviewFrequency_Select',
        title: 'Frequency of Base Pay increases',
        question:
            'How frequently does your organization typically give base pay increases to employees?',
    },
    {
        label: 'Prevalence of Salary Structures',
        value: 'Structure',
        title: 'Prevalence of Salary Structures',
        question:
            'Does your organization have formal salary structures with ranges or grades for employees?',
    },
    {
        label: 'Frequency of Salary Structure Adjustments',
        value: 'StructureFrequencySelect',
        title: 'Frequency of Salary Structure Adjustments',
        question:
            'How frequently does your organization typically adjust base salary structures?',
    },
    {
        label: 'Prevalence of Short-Term Cash Incentives',
        value: 'STI_Choice',
        title: 'Prevalence of Short-Term Cash Incentives',
        question:
            'Does your organization currently use or plan to use short-term cash incentives to reward employees?',
    },
    {
        label: 'Types of Short-Term Cash Incentives',
        value: 'STI_Type',
        title: 'Types of Short-Term Cash Incentives',
        question:
            'Which of the following types of short-term cash incentives does your organization use or plan to use within the next year?',
    },
    {
        label: 'Prevalence of Long-Term Incentives',
        value: 'LTI_Choice',
        title: 'Prevalence of Long-Term Incentives',
        question:
            'Does your organization currently use or plan to use equity compensation or any type of long-term incentive to reward employees?',
    },
    {
        label: 'Types of Long-Term Incentives',
        value: 'LTI_Type',
        title: 'Types of Long-Term Incentives',
        question:
            'Which of the following types of long-term incentives does your organization use or plan to use within the next year?',
    },
]

const PRACTICE_REPORT_2024_2025_OPTIONS = [
    {
        label: 'Frequency of Base Pay Increases',
        value: 'ReviewFrequency_Select',
        title: 'Frequency of Base Pay increases',
        question:
            'How frequently does your organization typically give base pay increases to employees?',
    },
    {
        label: 'Factors Used to Determine Base Pay Increases',
        value: 'BasePayFactors',
        title: 'Factors Used to Determine Base Pay Increases',
        question:
            'Which of the following factors does your organization typically use or plan to use to determine base salary increases?',
    },
    {
        label: 'Prevalence of Salary Structures',
        value: 'Structure',
        title: 'Prevalence of Salary Structures',
        question:
            'Does your organization have formal salary structures with ranges or grades for employees?',
    },
    {
        label: 'Frequency of Salary Structure Adjustments',
        value: 'StructureFrequencySelect',
        title: 'Frequency of Salary Structure Adjustments',
        question:
            'How frequently does your organization typically adjust base salary structures?',
    },
    {
        label: 'Prevalence of Short-Term Cash Incentives',
        value: 'STI_Choice',
        title: 'Prevalence of Short-Term Cash Incentives',
        question:
            'Does your organization currently use or plan to use short-term cash incentives to reward employees?',
    },
    {
        label: 'Types of Short-Term Cash Incentives',
        value: 'STI_Type',
        title: 'Types of Short-Term Cash Incentives',
        question:
            'Which of the following types of short-term cash incentives does your organization use or plan to use within the next year?',
    },
    {
        label: 'Prevalence of Long-Term Incentives',
        value: 'LTI_Choice',
        title: 'Prevalence of Long-Term Incentives',
        question:
            'Does your organization currently use or plan to use equity compensation or any type of long-term incentive to reward employees?',
    },
    {
        label: 'Types of Long-Term Incentives',
        value: 'LTI_Type',
        title: 'Types of Long-Term Incentives',
        question:
            'Which of the following types of long-term incentives does your organization use or plan to use within the next year?',
    },
]
export const PRACTICE_REPORT_OPTIONS = {
    [SURVEY_YEAR_2022_2023]: PRACTICE_REPORT_2022_2023_OPTIONS,
    [SURVEY_YEAR_2023_PRELIM]: PRACTICE_REPORT_2022_2023_OPTIONS,
    [SURVEY_YEAR_2023]: PRACTICE_REPORT_2022_2023_OPTIONS,
    [SURVEY_YEAR_2023_2024]: PRACTICE_REPORT_2023_2024_OPTIONS,
    [SURVEY_YEAR_2024]: PRACTICE_REPORT_2024_OPTIONS,
    [SURVEY_YEAR_2024_2025]: PRACTICE_REPORT_2024_2025_OPTIONS,
}

export const STI_TYPE = 'STI_Type'
export const LTI_TYPE = 'LTI_Type'
export const STI_PERCENT = 'STIPercent'
export const STI_TYPE_COLUMN_NAME = 'Types of Short-Term Cash Incentives\n \n '
export const LTI_TYPE_COLUMN_NAME = 'Types of Long-Term Incentives\n \n '
export const STI_BUDGETS_COLUMN_NAME =
    'Short-Term Cash Incentives \n      as % of Total Payroll'
