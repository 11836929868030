import React, { useEffect, useRef } from 'react'
import { scaleLinear, scaleBand } from 'd3-scale'
import * as d3 from 'd3'
import { DICT_KEY_GENRE, DICT_KEY_PERCENT } from '../constants/constants'

const Chart = ({ data, width, height, margin, transitionTime, maxValue }) => {
    const textMargin = 15
    const innerWidth = width - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom
    const ref = useRef()

    useEffect(() => {
        const svg = d3
            .select(ref.current)
            .attr('width', width)
            .attr('height', height)
            .style('border', '1px solid black')
    }, [])

    useEffect(() => {
        draw()
    }, [data, width, maxValue])

    const svg = d3.select(ref.current)

    const xValue = (d) => d[DICT_KEY_PERCENT]
    const yValue = (d) => d[DICT_KEY_GENRE]

    const x = scaleLinear().range([0, innerWidth])
    const y = scaleBand().range([0, innerHeight]).padding(0.1)

    // if maxValue is between 0 and 30, then x.domain is [0, 30], else if maxValue is between 30 and 50, then x.domain is [0, 50],else if maxValue is between 50 and 80, then x.domain is [0, 80], else if maxValue is between 80 and 100, then x.domain is [0, 100]
    if (maxValue <= 30) {
        x.domain([0, 30])
    } else if (maxValue <= 50) {
        x.domain([0, 50])
    } else if (maxValue <= 80) {
        x.domain([0, 80])
    } else {
        x.domain([0, 100])
    }

    // x.domain([0, 100])
    y.domain(data.map(yValue))

    const draw = () => {
        //bars
        const bars = svg.selectAll('rect').data(data)

        bars.enter()
            .append('rect')
            .transition()
            .duration(transitionTime)
            .attr('transform', `translate(${margin.left}, ${margin.top})`)
            .attr('y', (d) => y(yValue(d)))
            .attr('width', (d) => x(xValue(d)))
            .attr('height', y.bandwidth())

        bars.transition()
            .duration(transitionTime)
            .attr('transform', `translate(${margin.left}, ${margin.top})`)
            .attr('y', (d) => y(yValue(d)))
            .attr('width', (d) => x(xValue(d)))
            .attr('height', y.bandwidth())

        bars.exit().remove()

        //bar-texts
        const texts = svg.selectAll('text').data(data)

        texts
            .enter()
            .append('text')
            .transition()
            .duration(transitionTime)
            .attr('class', 'label')
            .attr('transform', `translate(${margin.left}, ${margin.top})`)
            .attr('x', (d) => x(xValue(d)) + textMargin)
            .attr('y', (d) => y(yValue(d)) + y.bandwidth() * 0.6)
            .text(function (d) {
                return xValue(d).toFixed(1) + '%'
            })
            .attr('font-family', 'sans-serif')
            .attr('font-size', '90%')
            .attr('fill', 'black')

        texts
            .transition()
            .duration(transitionTime)
            .attr('class', 'label')
            .attr('transform', `translate(${margin.left}, ${margin.top})`)

            .attr('x', (d) => x(xValue(d)) + textMargin)
            .attr('y', (d) => y(yValue(d)) + y.bandwidth() * 0.6)
            .text(function (d) {
                return xValue(d).toFixed(1) + '%'
            })
            .attr('font-family', 'sans-serif')
            .attr('font-size', '90%')
            .attr('fill', 'black')

        texts.exit().remove()
        //
        // var textLabel = svg
        //     .selectAll('text')
        //     .data('Percent of Companies (%)')
        //     .enter()
        //     .append('text')
        //
        //     .attr('text-anchor', 'middle')
        //     .attr('x', width - margin.left - margin.right)
        //     .attr('y', height - 6)
        //     .attr('font-family', 'sans-serif')
        //     .attr('font-size', '14px')
        //     .attr('fill', 'black')
        //     .text('Percent of Companies (%)')
        //
        // textLabel
        //     .transition()
        //     .duration(transitionTime)
        //     .text('Percent of Companies (%)')
        // textLabel.exit().remove()
    }

    return (
        <svg
            height={height}
            width={width}
            ref={ref}
            style={{ fill: '#2E76BB' }}
        ></svg>
    )
}

export default Chart
