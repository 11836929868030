import React from 'react'
import { REACT_APP_SERVER_ADDRESS } from '../config'
import axios from 'axios'
import {
    BANNER_SURVEY_NAME_SHORT_NO_CULPEPPER,
    SURVEY_UUID_LIST,
    SURVEY_YEAR_NAME_LIST,
} from '../constants/constants'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

function Participants() {
    return (
        <div>
            <UserInput />
        </div>
    )
}
const attributeName = 'participants'
const UserInput = () => {
    const params = useParams()
    const survey = SURVEY_UUID_LIST[params.survey]
    const [items, setItems] = useState([])
    const fetchDataAndUpdateTable = async () => {
        const options = {
            method: 'GET',
            url: `${REACT_APP_SERVER_ADDRESS}participants`,
            params: {
                survey: survey,
            },
        }

        try {
            const response = await axios.request(options)
            const respBody = response.data
            const newData = respBody.results
            const sortedNewData = newData.sort((a, b) =>
                a[attributeName].toLowerCase() > b[attributeName].toLowerCase()
                    ? 1
                    : -1
            )
            setItems(sortedNewData)
        } catch (e) {}
    }

    const showParticipants = items.map((item) => {
        return (
            <div className="row">
                <div className="col-lg-2 col-sm-1"></div>
                <div className="col-lg-10 col-sm-11">{item[attributeName]}</div>
            </div>
        )
    })

    useEffect(() => {
        fetchDataAndUpdateTable()
    }, [])

    return (
        <div className="container-fluid">
            <div className="row survey-participants-title">
                <div className="col-lg-2 col-sm-1"></div>
                <div className="col-lg-10 col-sm-11">
                    {SURVEY_YEAR_NAME_LIST[survey]}{' '}
                    {BANNER_SURVEY_NAME_SHORT_NO_CULPEPPER} Participants
                </div>
            </div>

            <div className="survey-participants">{showParticipants}</div>
        </div>
    )
}
export default Participants
