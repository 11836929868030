export const REACT_APP_SERVER_ADDRESS = process.env.REACT_APP_SERVER_ADDRESS
export const REACT_APP_PAGE_ADDRESS = process.env.REACT_APP_PAGE_ADDRESS
export const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY
export const REACT_APP_EXCEL_EXPORT = process.env.REACT_APP_EXCEL_EXPORT

// local-DEV
// export const REACT_APP_SERVER_ADDRESS = 'https://sbsbe-dev.culpepper.com/api/'
// export const REACT_APP_SERVER_ADDRESS = 'https://sls-eus-dev-temp-web-sbs-server.azurewebsites.net/api/'
// export const REACT_APP_PAGE_ADDRESS = 'http://localhost:3000/'
// export const REACT_APP_API_KEY = 'd589ba2e-2fd7-493e-81c5-4cc27699e5c6'
// export const REACT_APP_EXCEL_EXPORT =
//     'https://dev.private.culpepper.com/API/exportsbs/export'
